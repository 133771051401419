import {Alert, Box, Divider, Typography} from "@mui/material";
import React, {FormEvent, useContext, useState} from 'react'
import {useTranslation} from "react-i18next";
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import {AppContext} from "../../App";
import {resetPassword} from "../../services/AuthenticationService";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import BoxLetter from '../../assets/letter.svg';
import ElementInput from "../../patterns/form/elementInput";


type propsPasswordForget = {
  showForgetPassword: boolean
  setShowForgetPassword: (arg1: boolean) => void
}

export function PasswordForget(props: propsPasswordForget): JSX.Element {
  const {t} = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<string|null>(null)
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const [loading, setLoading] = useState<boolean>(false);

  const submitResetPassword = (e: FormEvent): void => {
    setLoading(true);
    e.preventDefault();
    resetPassword(email, store.operation.id)
      .then(() => setSuccess(true))
      .catch(() => setErrorEmail(t('reset_password.initialized.error_email')))
      .finally(() => {
        setLoading(false);
      })
  }

  const returnToLogin = (): void => {
    props.setShowForgetPassword(false);
    setSuccess(false);
    setErrorEmail(null);
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', maxWidth: "600px", mx: "auto"}}>
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mb: 3}}>
        <img src={BoxLetter} alt="Box Letter icon"/>
      </Box>
      <Box sx={{overflowY: 'hidden'}}>
        {success ? <>
          <Typography variant="body1" sx={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: t('reset_password.initialized.success_email', {email: email})}}/>
        </> : <>
          <Box>
            <Typography variant="body1" display="inline" sx={{fontWeight: "bold", mr: 0}}>{t('reset_password.initialized.text_info_bold')}</Typography>
            <Typography variant="body1" display="inline">{t('reset_password.initialized.text_info')}</Typography>
          </Box>
          <Box component="form" onSubmit={submitResetPassword} sx={{display: 'flex', flexDirection: 'column', mt: 5}}>
            <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mb: 1}}>{t('reset_password.initialized.label')}</Typography>
            <ElementInput
              name="email"
              label={t('reset_password.initialized.label')}
              type={INPUT_TYPES.EMAIL}
              required={true}
              updateValue={(e: any): any => setEmail(e.target.value)}
              disabled={loading}
            />
            {errorEmail && <Alert severity="error" variant="filled" sx={{mt: 3, mb: 3, width: "-webkit-fill-available"}}>{errorEmail}</Alert>}
            <PrimaryBigButton submit={true} sx={{mt: 7, mx: "auto", width: '100%'}} label={t("reset_password.initialized.confirm_button")} loading={loading} />
            <SecondaryBigButton submit={false} sx={{mt: 2, mx: "auto", width: '100%'}} label={t("reset_password.cancel_button_label")} action={returnToLogin}/>
          </Box>
        </>}
      </Box>
      <Divider sx={{backgroundColor: "ornament.dark", border: "none"}}/>
    </Box>
  )
}
