import {Avatar, Badge, Box, Button, Divider, Typography} from "@mui/material";
import React, {useContext, useRef, useState} from "react";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import HomeIcon from "@mui/icons-material/Home";
import {Link, useNavigate} from "react-router-dom";
import {RulesFormat} from "../../common/struct/models/TOperation";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import UserMenu from "./userMenu";
import {getBasketSize} from "../../common/struct/globalVar";
import JoozyBoy from "../../assets/JOOZY_BOY.svg";
import BasketMenu from "./basketMenu";

export default function GlobalHeader(): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [basketMenuOpen, setBasketMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null|HTMLElement>(null);

  const STORE = useContext<any>(AppContext);
  const [store] = STORE;
  const operation = store.operation;
  const rules = store.operation?.rules;
  const basketRef = useRef();

  const redirectToHome = (): void => navigate(PUBLIC_URL.HOME);
  const openUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(userMenuOpen ? null : event.currentTarget);
    setUserMenuOpen(!userMenuOpen);
  }
  const closeUserMenu = (): void => {
    setAnchorEl(null);
    setUserMenuOpen(false);
  }

  const openBasketMenu = (): void => {
    if (basketRef.current) {
      setAnchorEl(basketMenuOpen ? null : basketRef.current);
      setBasketMenuOpen(!basketMenuOpen);
    }
  }
  const closeBasketMenu = (): void => {
    setAnchorEl(null);
    setBasketMenuOpen(false);
  }

  const desktopMenu = <Box sx={{display: "flex", alignItems: "center", cursor: "pointer", height: "100%"}} onClick={redirectToHome}>
    {
      operation.logoUrl ? <img src={operation.logoUrl} style={{maxWidth: "204px", height: "100%", display: "block", objectFit: "contain", marginRight: "16px", padding: "8px 0px", boxSizing: "border-box"}} />
        :
        <>
          <Avatar sx={{backgroundColor: "ornament.dark", mr: 0, p: "0px", width: "auto", height: "auto"}}>
            <HomeIcon sx={{color: "neutral.main", p: 0, width: 20, height: 20}}/>
          </Avatar>
          <Typography variant="body1" color="neutral.main" sx={{fontWeight: "bold", fontSize: "18px", ml: 1}}>{t("header.home")}</Typography>
        </>
    }
  </Box>

  const mobileMenu = <Box sx={{display: "flex", alignItems: "center", cursor: "pointer", height: "100%"}} onClick={redirectToHome}>
    {
      operation.logoUrl ? <img src={operation.logoUrl} style={{height: "100%", display: "block", objectFit: "contain", marginRight: "16px", padding: "8px 0px", boxSizing: "border-box"}} />
        :
        <>
          <Avatar sx={{backgroundColor: "ornament.dark", mr: 0, p: "0px", width: "auto", height: "auto"}}>
            <HomeIcon sx={{color: "neutral.main", p: 0, width: 18, height: 18}}/>
          </Avatar>
          <Typography variant="body1" color="neutral.main" sx={{fontWeight: "bold"}}>{t("header.home")}</Typography>
        </>
    }
  </Box>

  const contactMenu = <Button variant="text" sx={{color: "neutral.main", fontWeight: "medium", mr: 3, fontSize: "16px"}} component={Link} to={PUBLIC_URL.CONTACT}>{t("header.contact")}</Button>

  const rulesMenu = <Button variant="text" sx={{color: "neutral.main", fontWeight: "medium", mr: 3}}
    href={rules?.format === RulesFormat.PDF ? rules?.content : ''}
    target={rules?.format === RulesFormat.PDF ? '_blank' : '_self'}
    disabled={rules == null}>
    {rules?.format === RulesFormat.PDF ?
      t("header.rules") :
      <Link to={PUBLIC_URL.RULES}>{t("header.rules")}</Link>
    }
  </Button>

  const basketMenu = 
    <Box sx={{height: "100%", display: "flex", alignItems: "center", boxSizing: "border-box", px: store.isMobile ? 2 : "unset", borderBottomColor: `${basketMenuOpen ? "primary.main" : "unset"}`, borderBottomStyle: `${basketMenuOpen ? "solid" : "unset"}`, borderBottomWidth: `${basketMenuOpen ? "3px" : "unset"}`}} ref={basketRef}>
      <Badge badgeContent={getBasketSize(store.basket)} color="primary" sx={{cursor: "pointer", mb: store.isMobile ? "0px" : "3px"}} onClick={openBasketMenu}>
        <ShoppingBasketIcon sx={{color: "neutral.main", height: "25px", width: "25px"}} />
        <BasketMenu basketMenuOpen={basketMenuOpen} closeBasketMenu={closeBasketMenu} anchorEl={anchorEl}/>
      </Badge>
    </Box>

  const userProfile = <Box sx={{display: "flex", alignItems: "center", cursor: "pointer", height: store.isMobile ? 48 : 63, borderBottom: store.isMobile ? "none" : "3px solid",
    borderColor: userMenuOpen ? "primary.main" : "transparent"}} onClick={openUserMenu}>
    {store.user?.participant?.firstName !== "" ?
      <Avatar sx={{backgroundColor: "ornament.dark", mr: 2, width: 26, height: 26}}>
        <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold", fontSize: "16px"}}>
          {store.user?.participant?.firstName?.substring(0, 1)}
        </Typography>
      </Avatar>
      :
      <Avatar sx={{backgroundColor: "ornament.dark", mr: 1, width: 26, height: 26}} src={JoozyBoy}/>
    }
    {!store.isMobile && <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mr: 2, fontSize: "16px"}}>
      {store.user?.participant?.firstName ? store.user?.participant?.firstName : t("header.account")}
    </Typography>}
    {!store.operation.shop.hidePricing && <Avatar variant="rounded" sx={{backgroundColor: "primary.main", p: 1, mr: 0, width: "unset", height: "unset"}}>
      <Typography variant="body2" color="primary.contrastText" sx={{fontWeight: "bold", mr: 0, fontSize: "16px"}}>
        {store.user?.rewardPoints}
      </Typography>
      <Typography variant="body2" color="primary.contrastText" sx={{fontSize: "16px", fontWeight: 700}}>
        {t("header.points")}
      </Typography>
    </Avatar>}
    {!userMenuOpen && <KeyboardArrowDownIcon sx={{width: 26, height: 26, color: "neutral.main"}}/>}
    {userMenuOpen && <KeyboardArrowUpIcon sx={{width: 26, height: 26, color: "neutral.main"}}/>}
    <UserMenu userMenuOpen={userMenuOpen} closeUserMenu={closeUserMenu} anchorEl={anchorEl}/>
  </Box>

  const loggedInMenu = <Box sx={{display: "flex", alignItems: "center", height: "100%"}}>
    {!store.isMobile && (store.isShopOpen || store.isUserSav || store.isPreview) && <Divider sx={{backgroundColor: "neutral.light", borderColor: "neutral.light", width: "1px", height: "12px", mr: 3, mb: "3px"}} orientation="vertical" />}
    {(store.isShopOpen || store.isUserSav || store.isPreview) && store.basket && basketMenu}
    {!store.isMobile && (store.isShopOpen || store.isUserSav || store.isPreview) && <Divider sx={{backgroundColor: "neutral.light", borderColor: "neutral.light", width: "1px", height: "12px", mx: 3, mb: "3px"}} orientation="vertical" />}
    {userProfile}
  </Box>

  const loggedOutMenu = <Button variant="contained" color="primary" component={Link} to={PUBLIC_URL.LOGIN} sx={{py: "0px", px: 1, boxShadow: 0}}>
    {t("header.login")}
  </Button>

  const hPadding = (store.isMobile) ? 2 : 5;
  const height = store.isMobile ? (operation.logoUrl ? 72 : 48) : (operation.logoUrl ? 100 : 66);
  return (
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", px: hPadding, height: height, overflowX: "auto", width: MAX_DESKTOP_SIZE}}>
      {!store.isMobile && desktopMenu}
      {store.isMobile && mobileMenu}
      <Box sx={{display: "flex", alignItems: "center", height: "100%"}}>
        {!store.isMobile && contactMenu}
        {!store.isMobile && rules && rulesMenu}
        {store.isLoggedIn && loggedInMenu}
        {!store.isLoggedIn && loggedOutMenu}
      </Box>
    </Box>
  )
}
