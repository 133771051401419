import {Box, LinearProgress, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ElementInput from "../../patterns/form/elementInput";
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import {TViolation} from "../../common/struct/models/TViolation";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import {AppContext} from "../../App";
import CheckConditions from "../basket/checkConditions";

type PasswordFieldsProps = {
  changePassword?: any,
  violations: TViolation[],
  setViolations: (violations: TViolation[]) => void,
  setCancel?: (arg0: boolean) => void,
  reset: boolean
}

export function PasswordForm(props: PasswordFieldsProps): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [passHasSize, setPassHasSize] = useState<boolean>(false);
  const [passHasUpper, setPassHasUpper] = useState<boolean>(false);
  const [passHasLower, setPassHasLower] = useState<boolean>(false);
  const [passHasDigit, setPassHasDigit] = useState<boolean>(false);
  const [passHasSpecial, setPassHasSpecial] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [progressState, setProgressState] = useState<string>(t('reset_password.progress_state.empty'));
  const [passwordViolations, setPasswordViolations] = useState<string[]>([]);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [checkConditions, setCheckConditions] = useState<boolean>(false);

  const changePassword = (e: any): void => {
    e.preventDefault();
    setLoading(true);
    if (props.changePassword) {
      if (oldPassword) {
        props.changePassword(password, oldPassword).finally(() => setLoading(false));
      } else {
        props.changePassword(password, new Date()).finally(() => setLoading(false));
      }
    }
  }

  const cancel = {
    onClick: (): void => {
      if (props.setCancel) props.setCancel(true)
    }
  }

  const updateOldPassword = (e: any): void => setOldPassword(e.target.value);

  const checkPassword = (password: string): void => {
    
    const violations = [];

    setPassHasSize(password.length >= 8);
    if (password.length < 8) {
      violations.push(t('reset_password.password_violation.too_short'));
    }

    setPassHasUpper(/[A-Z]/.test(password));
    if (!/[A-Z]/.test(password)) {
      violations.push(t('reset_password.password_violation.no_uppercase'))
    }

    setPassHasLower(/[a-z]/.test(password));
    if (!/[a-z]/.test(password)) {
      violations.push(t('reset_password.password_violation.no_lowercase'));
    }

    setPassHasDigit(/\d/.test(password));
    if (!/\d/.test(password)) {
      violations.push(t('reset_password.password_violation.no_digit'));
    }
    

    setPassHasSpecial(/[-_@$!%*#?&+/\\]/.test(password));
    if (!/[-_@$!%*#?&+/\\]/.test(password)) {
      violations.push(t('reset_password.password_violation.no_special'))
    }

    if (violations.length === 0 || (violations.length === 1 && password.length >= 16)) {
      setProgressState(t('reset_password.progress_state.strong'));
    } else if (violations.length === 1) {
      setProgressState(t('reset_password.progress_state.medium'));
    } else if (password.length > 0) {
      setProgressState(t('reset_password.progress_state.weak'));
    } else {
      setProgressState(t('reset_password.progress_state.empty'));
    }

    setPassword(password);
    setPasswordViolations(violations);
  }

  useEffect(() => {
    const passwordCriteria = [passHasUpper, passHasLower, passHasDigit, passHasSpecial];

    setProgressValue([passHasSize, ...passwordCriteria].filter(value => value).length * 100 / [passHasSize, ...passwordCriteria].length);
    setCanSubmit(passHasSize && passwordCriteria.filter(value => !value).length < 1);
  }, [passHasSize, passHasUpper, passHasLower, passHasDigit, passHasSpecial])   

  return (
    <form style={{display: 'flex', flexDirection: 'column'}} onSubmit={changePassword}>
      <Box sx={{px: 4, pb: props.setCancel ? 4 : 2, pt: props.setCancel ? 4 : 2, borderBottom: (props.reset || props.setCancel == null) ? "none" : "1px solid", borderColor: "ornament.dark"}}>
        {!props.reset && <Box sx={{mb: 4}}>
          <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mb: 2}}>{t('reset_password.label_old')}</Typography>
          <ElementInput name="oldPassword" violations={props.violations}
            label="reset_password.placeholder_security"
            type={INPUT_TYPES.PASSWORD}
            password={true}
            disabled={loading}
            updateValue={updateOldPassword}
          />
        </Box>}
        <Box sx={{mb: 4}}>
          <Typography variant="body2" sx={{
            fontWeight: "bold",
            color: "neutral.main",
            mb: 1
          }}>{t('reset_password.label_new')}</Typography>
          <ElementInput name="password" violations={props.violations}
            label="reset_password.placeholder_security"
            type={INPUT_TYPES.PASSWORD}
            password={true}
            disabled={loading}
            updateValue={(e: any): void => checkPassword(e.target.value)}/>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <LinearProgress sx={{backgroundColor: "ornament.dark", flexGrow: 1}} color={canSubmit ? "success" : "error"} variant="determinate" value={progressValue} />
            <Typography variant="caption" display="inline" sx={{ml: 1, color: "neutral.main"}}>{progressState}</Typography>
          </Box>
          {passwordViolations.map((violation, index) => (
            <Box key={index} sx={{display: "flex", alignItems: "center"}}>
              <CheckCircleOutlineIcon sx={{color: "neutral.main", mr: 0}}/>
              <Typography variant="caption" display="inline" sx={{color: "neutral.main"}}>{violation}</Typography>
            </Box>
          ))}
        </Box>
        {props.reset && store.user.cgvuDate == null && <Box sx={{mb: 4}}>
          <CheckConditions checkConditions={checkConditions} setCheckConditions={setCheckConditions}/>
        </Box>}
      </Box>
      {props.reset ?
        <Box sx={{px: 4, pb: 4}}>
          <PrimaryBigButton sx={{width: "100%"}} submit={true} label={t("reset_password.confirm_reset")} action={changePassword} disabled={!canSubmit || (store.user.cgvuDate == null && !checkConditions)}/>
        </Box> :
        <Box sx={{display: "flex", flexDirection: 'row', justifyContent: props.setCancel ? "end" : "left", p: 4}}>
          {props.setCancel && <SecondaryBigButton submit={false} label={t("reset_password.cancel_button_label")} sx={{mr: 3}}
            action={cancel.onClick}/>}
          <PrimaryBigButton loading={loading} submit={true} sx={{width: store.isMobile ? "100%" : "inherit"}}
            label={t("reset_password.confirm_update" + (store.isMobile ? "_mobile" : ""))} action={changePassword} disabled={!canSubmit}/>
        </Box>
      }
    </form>
  )
}
