import Carrousel from "../../components/carrousel/carrousel";
import {Box, Card, Typography} from "@mui/material";
import CardMediaLoader from "../../components/image/cardMediaLoader";
import Loader from "../../components/loader/loader";
import React, {useState} from "react";
import {TBrand, TGiftImage} from "../../common/struct/models/TGift";

interface GiftPicturesProps {
  images: TGiftImage[];
  brand: TBrand|null;
  isMobile: boolean;
}

export default function GiftPictures(props: GiftPicturesProps): JSX.Element {
  const {images, brand, isMobile} = props;
  const [imageError, setImageError] = useState<boolean>(false);
  const [loadingImage, setLoadingImage] = useState<boolean>(true);

  return (
    <Box sx={{position: "relative"}}>
      {images.length == 0 && <Box sx={{height: "498px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <CardMediaLoader image={null} isMobile={isMobile} sx={{height: "100%"}}/>
      </Box>}
      {images.length > 0 && <Carrousel images={images} sx={{
        ".image-gallery": {
          ".image-gallery-slide-wrapper": {
            margin: !isMobile ? "0 0 16px 0" : "0 0 12px 0",
            ".image-gallery-icon": !isMobile ? {
              filter: "none",
              padding: "0"
            } : {display: "none"},
            ".original-image": {
              "img": {
                height: !isMobile ? "498px" : "331px",
                maxWidth: !isMobile ? "498px" : "initial",
                borderRadius: !isMobile ? "initial" : "4px"
              }
            }
          },
          ".image-gallery-thumbnails-wrapper": {
            padding: "8px",
            height: !isMobile ? "initial" : "80px",
            backgroundColor: "ornament.main",
            boxShadow: "0px 2px 0px rgba(32, 34, 36, 0.15)",
            borderRadius: "4px",
            ".image-gallery-thumbnails-container": {
              cursor: "default",
              ".image-gallery-thumbnail": {
                cursor: "pointer",
                borderColor: "ornament.dark",
                borderWidth: "2px",
                borderStyle: "solid"
              },
              ".image-gallery-thumbnail:last-child": {
                margin: "0"
              },
              ".active": {
                borderColor: "primary.main",
                borderWidth: "2px",
                borderStyle: "solid"
              },
              ".thumbnail-image": {
                height: "72px",
                width: "72px",
                backgroundColor: "ornament.light",
                boxSizing: "border-box",
                borderRadius: "4px",
                margin: "0 12px 0 0",
                padding: "4px",
                "img": {
                  height: "60px",
                  width: "60px"
                }
              }
            }
          }
        },
        mb: !isMobile ? 6 : 4
      }}/>}
      {brand && <Card sx={{position: "absolute", transform: !isMobile ? "none" : "translateY(-50%)",
        height: !isMobile ? "95px" : "72px", width: !isMobile ? "95px" : "72px", top: "0", left: !isMobile ? "0" : "8px",
        boxSizing: "border-box", border: "1px solid", borderColor: "ornament.dark", boxShadow: 0}}>
        {brand.image_url && !imageError ? <>
          <img
            src={brand.image_url}
            alt={"Brand logo"}
            style={{display: loadingImage ? 'none' : 'block', width: "100%", height: "100%", objectFit: "contain"}}
            onError={(): void => {
              setImageError(true);
              setLoadingImage(false);
            }}
            onLoad={(): void => setLoadingImage(false)}
          />
          {loadingImage && <Loader size={30} sx={{height: "140px"}}/>}
        </> : <Typography variant="body2" component="div" sx={{display: "display", justifyCenter: "center", alignItems: "center", color: "neutral.dark", fontWeight: "400"}}>
          {brand.name}
        </Typography>
        }
      </Card>}
    </Box>
  )
}