import {Box, Typography, Divider} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";
import LoginWithPassword from "./LoginWithPassword";
import RegisterWithCode from "./registerWithCode";
import RegisterWithCodeForm from "./registerWithCodeForm";
import {PasswordForget} from "./passwordForget";
import {ReactComponent as Smile} from "../../assets/smile.svg";
import Footer from "../../patterns/footer/footer";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {checkIsCodeEnable} from "../../common/struct/globalVar";

export default function LoginPage(): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);
  const operation = store.operation;
  const isMobile = store.isMobile;  

  const [amount, setAmount] = useState<number|null>(null);
  const [showRegister, setShowRegister] = useState<boolean>(false);
  const [code, setCode] = useState({
    value: '',
    mask: '**** - **** - **** - ****'
  })

  useEffect(() => {
    document.title = t("tab_title.login");
  }, []);

  const rightRef: any = useRef(null);

  useEffect(() => {
    const handleWheel = (event: any): void => {
      if (event.deltaY !== 0 && rightRef.current && !isMobile) {
        rightRef.current.scrollBy(0, event.deltaY);
      }
    };

    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const [showForgetPassword, setShowForgetPassword] = useState(false);

  return (
    <Box sx={{width: "100vw", height: "100vh", display: "flex"}}>
      <Box sx={{width: "50%", height: "100%", backgroundColor: "primary.main", position: "relative", display: isMobile ? "none" : "block"}}>
        <Box sx={{width: "fit-content", height: "fit-content", position: "absolute", top: "0%", right: "-72px"}}>
          <Smile />
        </Box>
      </Box>
      <Box sx={{width: isMobile ? "100%" : "50%", minWidth: isMobile ? "unset" : "680px", height: "100%", display: "flex", flexDirection: "column", backgroundColor: "ornament.main", overflow: "auto"}} ref={rightRef}>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", px: "45px", py: "25px", borderBottomColor: "ornament.dark", borderBottomStyle: "solid", borderBottomWidth: "1px", flexShrink: 0}}>
          {operation.logoUrl && <Box component="img" alt="Operation logo" src={operation.logoUrl} sx={{maxWidth: "110px", maxHeight: "110px"}} />}
          <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold", px: 5}}>{operation.name}</Typography>
        </Box>
        <Box sx={{flex: 1, px: "45px", py: "25px"}}>
          {!showRegister ? <>
            {!showForgetPassword ? <LoginWithPassword showForgetPassword={showForgetPassword} setShowForgetPassword={setShowForgetPassword}/>
              : <PasswordForget showForgetPassword={showForgetPassword} setShowForgetPassword={setShowForgetPassword}/>}
            {!showForgetPassword && checkIsCodeEnable(operation) && 
            <>
              <Box sx={{width: "100%", mb: 4, mt: 6, position: "relative"}}>
                <Divider sx={{backgroundColor: "neutral.light", borderRadius: "5px", height: "1px", maxWidth: "600px", margin: "0 auto"}} />
                <Typography variant="body2" color="neutral.main" sx={{px: 2, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "ornament.main", textTransform: "uppercase"}}>{t("login.or")}</Typography>
              </Box>
              <RegisterWithCode code={code} setCode={setCode} setRegister={setShowRegister} setAmount={setAmount}/>
              <Box sx={{maxWidth: "600px", mx: "auto", mt: 2}}>
                <Typography variant="caption" color="neutral.dark" sx={{"a": {textDecoration: "none", color: "neutral.main"}, "strong": {color: "neutral.dark"}}} dangerouslySetInnerHTML={{__html: t("login.datas_infos", {link: PUBLIC_URL.PERSONAL_DATA})}}/>
              </Box>
            </>}
          </>
            : <>
              <RegisterWithCodeForm code={code} amount={amount}/>
            </>}
        </Box>
        <Footer sx={{flexShrink: 0}} scrollButton={false} />
      </Box>
    </Box> 
  )
}