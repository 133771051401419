import {TOrder} from "./TOrder";
import {TAccessCode} from "./TAccessCode";

export enum TRewardCategory {
  UNITARY_CREDIT = 'UNITARY_CREDIT',
  UNITARY_DEBIT = 'UNITARY_DEBIT',
  IMPORT_CREDIT = 'IMPORT_CREDIT',
  IMPORT_DEBIT = 'IMPORT_DEBIT',
  REFUND = 'REFUND',
  ORDER = 'ORDER',
  EXPIRATION = 'EXPIRATION',
  CODE = 'CODE'
}

export interface TReward {
    "@type": string;
    "@id": string;
    category: TRewardCategory;
    giftOrder: TOrder;
    pointAmount: number;
    createdAt: string;
    updatedAt: string;
    accessCode?: TAccessCode;
}

