import React, {useContext, useState} from 'react';
import {Alert, Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SecondaryNegativeBigButton} from "../../components/buttons/mainButton";
import {SxProps, Theme} from '@mui/material/styles';
import InputMask from 'react-input-mask';
import {validateAccessCode} from '../../services/OperationService';
import {AppContext} from '../../App';
import {manageStringError} from '../../common/methods/ApiService';

type propsRegisterWithCode = {
  code: {value: string; mask: string}
  setCode: (arg: any) => void
  setRegister: (arg: boolean) => void
  setAmount: (arg: number|null) => void
  sx?: SxProps<Theme>
}

export default function RegisterWithCode({code, setCode, setRegister, setAmount, sx}: propsRegisterWithCode): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);
  const operation = store.operation;

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onChange = (e: any): void => {  
    setCode((prevState: any) => ({
      ...prevState,
      value: e.target.value
    }))
  }

  const submit = (e: any): void => {  
    setLoading(true);
    e.preventDefault();
    if (e.target.id === "code-form") {
      validateAccessCode(operation.id, code.value.replace(/ /g, '').toUpperCase()).then((res) => {
        setAmount(res.amount)
        setRegister(true);
      }).catch((error) => {
        manageStringError(error, setError, t);
      }).finally(() => {
        setLoading(false);
      })
    }
  }

  return (
    <>
      <Box component="form" onSubmit={submit} id={"code-form"}
        sx={{display: 'flex', flexDirection: 'column', width: '100%', maxWidth: "600px", mx: "auto", ...sx}}>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 4}}>{t("login.code.title")}</Typography>
        <Typography variant="body2" color="neutral.main"
          sx={{fontWeight: "bold", mb: 1}}>
          {t("login.code.label")}
        </Typography>
        <Box sx={{mb: 4, display: "flex", alignItems: "center"}}>
          <Box sx={{width: "100%", border: "1px solid #BEBFC0", borderRadius: "4px", "input": {width: "100%", borderRadius: "4px", border: "2px solid white", letterSpacing: "2px", backgroundColor: "inherit", textTransform: "uppercase", boxSizing: "border-box", padding: "14.5px 12px", fontSize: "18px", textAlign: "center", fontFamily: "Mulish", color: "#838384"}, "input:focus": {borderColor: "primary.main", borderWidth: "2px", outline: "none"}}}>
            <InputMask {...code} onChange={onChange} disabled={loading} placeholder={"XXXX - XXXX - XXXX - XXXX"} />
          </Box>
        </Box>
        {error && <Alert severity="error" variant="filled" sx={{mb: 4, width: "initial !important", alignItems: "center", "a": {textDecoration: "none", fontWeight: "bold", color: "error.dark"}}}><span dangerouslySetInnerHTML={{__html: error}} /></Alert>}
        <SecondaryNegativeBigButton submit={true} loading={loading} label={t("login.code.button")}/>
      </Box>
    </>
  )
}
