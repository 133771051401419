import {Box, Checkbox, FormControlLabel, SxProps, Theme, Typography} from "@mui/material";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import React from "react";
import {useTranslation} from "react-i18next";

interface CheckConditionsProps {
  checkConditions: boolean;
  setCheckConditions: (check: boolean) => void;
  sx?: SxProps<Theme>,
}

export default function CheckConditions(props: CheckConditionsProps): JSX.Element {
  const {t} = useTranslation();
  const {checkConditions, setCheckConditions, sx} = props;

  return (
    <Box sx={{...sx}}>
      <FormControlLabel sx={{mx: "0px", textAlign: "justify", alignItems: "initial"}} control={
        <Checkbox
          checked={checkConditions}
          sx={{color: "primary.main", width: "24px", height: "24px", mr: 2, "& .MuiSvgIcon-root": {fontSize: "24px"}}}
          onClick= {(): void => setCheckConditions(!checkConditions)}
        />
      } label={
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography variant="body2" color="neutral.main" sx={{mr: 2, fontWeight: "medium", textAlign: "justify", "a": {textDecoration: "none", color: "neutral.main"}, "strong": {color: "neutral.dark"}}} dangerouslySetInnerHTML={{__html: t("basket.overview.conditions", {link_cgvu: PUBLIC_URL.CGVU, link_ppdp: PUBLIC_URL.PERSONAL_DATA})}} />
        </Box>
      } />
    </Box>
  )
}
