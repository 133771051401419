export interface TShop {
  id: string;
  pricing: string;
  hidePricing: boolean;
  blockedDate: string;
}

export interface TOperation {
  id: string;
  logoUrl?: string;
  bannerUrl?: string;
  faviconUrl?: string;
  name: string;
  reference: string;
  shop: TShop;
  presentation?: string;
  primaryColor: string;
  contrastColor: string;
  rules?: TRules | null;
  startDate?: string;
  endDate?: string;
  expirationDate?: string;
  complementActivated: boolean;
  codeEnable: boolean;
  twig?: string; 
}

export enum RulesFormat {
  PDF = "PDF",
  TEXT = "TEXT"
}

export interface TRules {
  format: RulesFormat,
  content: string
}
