import React, {useContext, useState} from 'react';
import {AppContext} from "../../App";
import {Alert, Box, Card, Divider, Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from "react-router-dom";
import {PUBLIC_URL} from '../../common/struct/urlManager';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import {PrimaryBigButton} from '../../components/buttons/mainButton';
import Bounce from '../../patterns/bounce/bounce';
import moment from "moment";
import InputMask from 'react-input-mask';
import {validateAccessCode} from '../../services/OperationService';
import {manageStringError} from '../../common/methods/ApiService';
import {getMinimalUserInfo} from '../../services/ParticipantService';
import {checkIsCodeEnable, getOperationParticipantId} from '../../common/struct/globalVar';
import {globalStoreReducer} from '../../common/methods/context/globalStoreReducer';
import {StoreActions} from '../../common/struct/store';

export default function PointsSideCard(props: {setReload: (arg: boolean) => void, setCodeModal: (arg: boolean) => void, setAmount: (arg: number|null) => void}): JSX.Element {
  const {setReload, setCodeModal, setAmount} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;
  const params = new URLSearchParams(useLocation().search);
  const isCode = params.get('code') === 'true';
  const operation = store.operation;
  const participant = store.user.participant;    

  const matchesM = useMediaQuery('(min-width:1250px)');
  const matchesS = useMediaQuery('(min-width:1150px)');
    
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState({
    value: '',
    mask: '**** - **** - **** - ****'
  })

  const onChange = (e: any): void => {  
    setCode((prevState: any) => ({
      ...prevState,
      value: e.target.value
    }))
  }

  const submit = (e: any): void => {  
    setLoading(true); 
    setError(null);   
    e.preventDefault();
    if (e.target.id === "code-form") {
      validateAccessCode(operation.id, code.value.replace(/ /g, '').toUpperCase(), participant?.email).then((res) => {
        if (params.has('code')) {
          params.delete('code');
          const newSearch = params.toString();
          navigate({
            pathname: location.pathname,
            search: newSearch,
          }, {replace: true});
        }
        setCode((prevState: any) => ({
          ...prevState,
          value: ""
        }))
        setAmount(res.amount)
        getMinimalUserInfo(getOperationParticipantId() ?? "")
          .then((participant) => {
            setReload(true);
            setCodeModal(true);
            globalStoreReducer(STORE, StoreActions.LOGIN, {user: participant});
          });
      }).catch((error) => {
        manageStringError(error, setError, t);
      }).finally(() => {
        setLoading(false);
      })
    }
  }  

  const redirectToShop = (): void => navigate(PUBLIC_URL.HOME);
  const isShopOpen = store.operation && ((moment(store.operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date() && !store.operation.endDate) || (moment(store.operation.startDate, 'YYYY-MM-DD HH:mm:ss').toDate() <= new Date() && moment(store.operation.endDate, 'YYYY-MM-DD HH:mm:ss').toDate() >= new Date()));

  const codeCard = <Card sx={{width: matchesM ? "100%" : matchesS ? "35%" : "none", maxWidth: matchesS ? "386px" : "none", ml: matchesS ? 4 : "0px", height: "fit-content", p: 4, borderWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark", minWidth: "380px"}}>
    <Box>
      <Typography variant="body2" sx={{mb: 0, fontWeight: 400, color: "neutral.main"}}>{store.user.accessCodes.length == 0 ? t("account.points.access_code_title") : t("account.points.access_code_title_new")}</Typography>
      <Box component="form" onSubmit={submit} id={"code-form"}>
        <Typography variant="body2" color="neutral.main"
          sx={{fontWeight: "bold", mb: 1}}>
          {t("login.code.label")}
        </Typography>
        <Box sx={{mb: 4, display: "flex", alignItems: "center"}}>
          <Box sx={{width: "100%", border: "1px solid #BEBFC0", borderRadius: "4px", "input": {width: "100%", borderRadius: "4px", border: "2px solid white", letterSpacing: "2px", backgroundColor: "inherit", textTransform: "uppercase", boxSizing: "border-box", padding: "14.5px 12px", fontSize: "18px", textAlign: "center", fontFamily: "Mulish", color: "#838384"}, "input:focus": {borderColor: "primary.main", borderWidth: "2px", outline: "none"}}}>
            <InputMask {...code} onChange={onChange} disabled={loading} placeholder={"XXXX - XXXX - XXXX - XXXX"} />
          </Box>
        </Box>
        {error && <Alert severity="error" variant="filled" sx={{mb: 4, width: "initial !important", alignItems: "center", "a": {textDecoration: "none", fontWeight: "bold", color: "error.dark"}}}><span dangerouslySetInnerHTML={{__html: error}} /></Alert>}
        <Divider sx={{backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "100%", mb: 4}} />
        <PrimaryBigButton submit={true} loading={loading} label={t("login.code.button")} sx={{width: "100%"}} />
      </Box>
    </Box>
  </Card>

  return (
    <Box sx={{display: "flex", flexDirection: "column", ".MuiPaper-root": {ml: "0px", boxSizing: "border-box"}}}>
      <Card sx={{width: matchesM ? "100%" : matchesS ? "35%" : "none", maxWidth: matchesS ? "386px" : "none", ml: matchesS ? 4 : "0px", height: "fit-content", p: 4, borderWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark", minWidth: "380px", mb: 3}}>
        <Box>
          <Typography variant="body2" sx={{mb: 0, fontWeight: 400, color: "neutral.main"}}>{t("account.points.current_date_amount", {date: new Date})}</Typography>
          <Box sx={{display: "flex"}}>
            <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold"}}>
              {store.user && store.user.rewardPoints}
            </Typography>
            <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold", fontSize: "22px", ml: "8px", mt: "-6px"}}>
              {t('account.points.point', {count: store.user && store.user.rewardPoints})}
            </Typography>
          </Box>
        </Box>
        {store.operation && store.operation.expirationDate &&
          <>
            <Divider sx={{backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "100%", mb: 4}} />
            {parseInt(new Number((new Date(store.operation.expirationDate).getTime() / 86400000) - (new Date().getTime() / 86400000)).toFixed(0)) > 30 ?
              <Box sx={{mt: 4}}>
                <Typography variant="body2" sx={{mb: 0, fontWeight: 400, color: "neutral.main"}}>{t("account.points.expiry_date")}</Typography>
                <Box sx={{display: "flex", py: 1, px: 2, backgroundColor: "info.light", borderRadius: 1}}>
                  <CheckIcon sx={{width: "24px", height: "24px", color: "info.main", mr: 1}} />
                  <Typography variant="body1" color="info.dark" sx={{fontWeight: "medium"}}>{t("account.points.full_date", {date: store.operation && new Date(store.operation.expirationDate)})}</Typography>
                </Box>
              </Box>
              : (parseInt(new Number((new Date(store.operation.expirationDate).getTime() / 86400000) - (new Date().getTime() / 86400000)).toFixed(0)) <= 30 && parseInt(new Number((new Date(store.operation.expirationDate).getTime() / 86400000) - (new Date().getTime() / 86400000)).toFixed(0)) > 0) ||
                  (parseInt(new Number((new Date(store.operation.expirationDate).getTime() / 86400000) - (new Date().getTime() / 86400000)).toFixed(0)) <= 30 && new Date() < new Date(store.operation.expirationDate)) ?
                <Box sx={{mt: 4}}>
                  <Typography variant="body2" sx={{mb: 0, fontWeight: 400, color: "neutral.main"}}>{t("account.points.expiry_date")}</Typography>
                  <Box sx={{display: "flex", py: 1, px: 2, backgroundColor: "warning.light", borderRadius: 1}}>
                    <WarningAmberIcon sx={{width: "24px", height: "24px", color: "warning.main", mr: 1}} />
                    <Typography variant="body1" color="warning.dark" sx={{fontWeight: "medium"}}>{t("account.points.full_date", {date: store.operation && new Date(store.operation.expirationDate)})}</Typography>
                  </Box>
                </Box>
                :
                <Box sx={{mt: 4}}>
                  <Typography variant="body2" sx={{mb: 0, fontWeight: 400, color: "neutral.main"}}>{t("account.points.expiry_date")}</Typography>
                  <Box sx={{display: "flex", py: 1, px: 2, backgroundColor: "neutral.light", borderRadius: 1}}>
                    <StopCircleIcon sx={{width: "24px", height: "24px", color: "neutral.main", mr: 1}} />
                    <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "medium"}}>{t("account.points.expiry", {date: store.operation && new Date(store.operation.expirationDate)})}</Typography>
                  </Box>
                </Box>
            }
          </>
        }
        {isShopOpen && <PrimaryBigButton label={t("account.points.button")} sx={{height: "fit-content", width: "100%", mt: 4}} action={redirectToShop} />}
      </Card>
      {checkIsCodeEnable(store.operation) && (
        isCode ? <Bounce sx={{borderColor: "primary.main", borderWidth: "2px", backgroundColor: "primaryLight.light", borderStyle: "solid", borderRadius: "4px", ".MuiCard-root": {backgroundColor: "primaryLight.light", boxShadow: "none"}}}>
          {codeCard}
        </Bounce>
          : codeCard
      )}
    </Box>
  )
}
