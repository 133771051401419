import React, {createContext, useEffect, useState} from 'react';
import {initOperation, initOperationMinimal} from "./services/OperationService";
import Home from "./pages/home/home";
import Gift from "./pages/gift/gift";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Loader from "./components/loader/loader";
import {PUBLIC_URL} from './common/struct/urlManager';
import {Box, ThemeProvider} from "@mui/material";
import GoogleAnalyticsTracker from "./patterns/googleAnalytics/googleAnalyticsTracker";
import Rules from "./pages/rules/Rules";
import Account from "./pages/account/account";
import Orders from './pages/orders/orders';
import Points from './pages/points/points';
import {Helmet} from "react-helmet";
import ResetPassword from "./pages/resetPassword/resetPassword";
import ScrollToTop from "./services/ScrollToTopService";
import customRoute from "./common/auth/customRoute";
import {LibertyTheme} from "./tokens/libertyTheme";
import {initialStore, StoreActions, StoreContext} from "./common/struct/store";
import Basket from "./pages/basket/basket";
import BasketShippingAddress from './pages/basket/basketShippingAddress';
import {globalStoreReducer} from "./common/methods/context/globalStoreReducer";
import {getMinimalUserInfo} from "./services/ParticipantService";
import {getOperationParticipantId} from "./common/struct/globalVar";
import OrderDone from "./pages/order/orderDone";
import Order from "./pages/order/order";
import Information from "./pages/information/information";
import Faq from "./pages/faq/faq";
import Contact from "./pages/contact/contact";
import Unsuscribe from "./pages/unsuscribe/unsuscribe";
import {loginWithToken} from "./services/AuthenticationService";
import BasketBillingAddress from './pages/basket/basketBillingAddress';
import BasketPayment from "./pages/basket/basketPayment";
import Maintenance from "./pages/maintenance/maintenance";
import ExpiredToken from "./pages/expiredToken/expiredToken";
import LoginPage from "./pages/login/loginPage";
import {getMinimalAdminInfo, initOperationAdmin} from './services/AdminService';
import publicRoute from './common/auth/publicRoute';
import privateRoute from './common/auth/privateRoute';

// @ts-ignore
export const AppContext = createContext<StoreContext>();

export default function App(): JSX.Element {
  const DEFAULT_PRIMARY_COLOR = "#6725D2";
  const LIGHT_DEFAULT_CONTRAST_COLOR = '#FFFFFF';
  const DARK_DEFAULT_CONTRAST_COLOR = '#3A3A3A';

  const [state, setState] = useState(initialStore);
  const [faviconUrl, setFaviconUrl] = useState("favicon.png");
  const [error, setError] = useState<string | null>(null);
  const [maintenance, setMaintenance] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [primaryColor, setPrimaryColor] = useState(DEFAULT_PRIMARY_COLOR);
  const [contrastColor, setContrastColor] = useState(LIGHT_DEFAULT_CONTRAST_COLOR);
  const theme = LibertyTheme(primaryColor, contrastColor);

  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const unsuscribe = new URLSearchParams(search).get('unsuscribe');
  const redirectUrl = new URLSearchParams(search).get('redirect');
  const TOKEN_EXPIRED_ERROR = 'Token expired';
  const CONNECT_ERROR = 'Connect';
  const navigate = useNavigate();

  const initOperationHandler = (): any => {
    initOperation()
      .then((data: any) => {
        if (data.faviconUrl) setFaviconUrl(data.faviconUrl);        
        globalStoreReducer([state, setState], StoreActions.SET_OPERATION, {operation: data});
        globalStoreReducer([state, setState], StoreActions.SET_THEME,{theme: LibertyTheme(data.primaryColor, data.contrastColor?DARK_DEFAULT_CONTRAST_COLOR:LIGHT_DEFAULT_CONTRAST_COLOR)});
        setPrimaryColor(data.primaryColor);
        setContrastColor(data.contrastColor?DARK_DEFAULT_CONTRAST_COLOR:LIGHT_DEFAULT_CONTRAST_COLOR);
      })
      .catch((error: any) => {
        if (error.status == 503) {
          setMaintenance(true);
          setLoading(false);
        } else if (error.status == 401) {
          initOperationAdmin()
            .then((data: any) => {
              if (data.faviconUrl) setFaviconUrl(data.faviconUrl);        
              globalStoreReducer([state, setState], StoreActions.SET_OPERATION, {operation: data});
              globalStoreReducer([state, setState], StoreActions.SET_THEME,{theme: LibertyTheme(data.primaryColor, data.contrastColor?DARK_DEFAULT_CONTRAST_COLOR:LIGHT_DEFAULT_CONTRAST_COLOR)});
              setPrimaryColor(data.primaryColor);
              setContrastColor(data.contrastColor?DARK_DEFAULT_CONTRAST_COLOR:LIGHT_DEFAULT_CONTRAST_COLOR);
            })
            .catch((error: any) => {
              if (error.status == 503) {
                setMaintenance(true);
                setLoading(false);
              } else {
                setError(error);
                setLoading(false);
              }
            })
            .finally(() => setLoading(false));
        } else {
          setError(error);
          setLoading(false);
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    initOperationMinimal()
      .then((data: any): void => {        
        globalStoreReducer([state, setState], StoreActions.SET_OPERATION, {operation: data});
        globalStoreReducer([state, setState], StoreActions.SET_THEME,{theme: LibertyTheme(data.primaryColor, data.contrastColor?DARK_DEFAULT_CONTRAST_COLOR:LIGHT_DEFAULT_CONTRAST_COLOR)});
        setPrimaryColor(data.primaryColor);
        setContrastColor(data.contrastColor?DARK_DEFAULT_CONTRAST_COLOR:LIGHT_DEFAULT_CONTRAST_COLOR);        

        if (token !== null && data !== null) {
          setLoading(true);
          loginWithToken([state, setState], token, data.id)
            .then(() => {              
              initOperationHandler().then(() => {
                if (redirectUrl !== null && redirectUrl !== '') {
                  navigate("/" + redirectUrl);
                } else if (unsuscribe === "true") {
                  setLoading(false);
                  navigate(PUBLIC_URL.UNSUSCRIBE);
                } else {
                  setLoading(false);
                  navigate(PUBLIC_URL.HOME);
                }
              })  
            })
            .catch((err) => {
              if (err === TOKEN_EXPIRED_ERROR) {
                setLoading(false);
                navigate(PUBLIC_URL.EXPIRED_TOKEN);
              } else if (err === CONNECT_ERROR) {
                setLoading(false);
                navigate(PUBLIC_URL.LOGIN);
              } else {
                setLoading(false);
                navigate(PUBLIC_URL.HOME);
              }
            });
        } else {
          if (getOperationParticipantId()) {
            getMinimalUserInfo(getOperationParticipantId() ?? "")
              .then((user) => {
                globalStoreReducer([state, setState], StoreActions.LOGIN, {user: user});
                initOperationHandler();
              })
              .catch(() => {
                return getMinimalAdminInfo(getOperationParticipantId() ?? "")
                  .then((user) => {
                    globalStoreReducer([state, setState], StoreActions.LOGIN, {user: user});
                    globalStoreReducer([state, setState], StoreActions.UPDATE_IS_PREVIEW, {isPreview: true});
                    initOperationAdmin()
                      .then((data: any) => {
                        if (data.faviconUrl) setFaviconUrl(data.faviconUrl);        
                        globalStoreReducer([state, setState], StoreActions.SET_OPERATION, {operation: data});
                        globalStoreReducer([state, setState], StoreActions.SET_THEME,{theme: LibertyTheme(data.primaryColor, data.contrastColor?DARK_DEFAULT_CONTRAST_COLOR:LIGHT_DEFAULT_CONTRAST_COLOR)});
                        setPrimaryColor(data.primaryColor);
                        setContrastColor(data.contrastColor?DARK_DEFAULT_CONTRAST_COLOR:LIGHT_DEFAULT_CONTRAST_COLOR);
                      })
                      .catch((error: any) => {
                        if (error.status == 503) {
                          setMaintenance(true);
                          setLoading(false);
                        } else {
                          setError(error);
                          setLoading(false);
                        }
                      })
                      .finally(() => setLoading(false));
                  })
                  .catch(() => {
                    globalStoreReducer([state, setState], StoreActions.LOGOUT);
                    setLoading(false);
                  })
              })
              .catch((err) => {              
                if (err === TOKEN_EXPIRED_ERROR) {
                  setLoading(false);
                  navigate(PUBLIC_URL.EXPIRED_TOKEN);
                } else if (err === CONNECT_ERROR) {
                  setLoading(false);
                  navigate(PUBLIC_URL.LOGIN);
                } else {
                  setLoading(false);
                  navigate(PUBLIC_URL.HOME);
                }
              }
              );
          } else {
            setLoading(false);
          }
        }
      })   

    const handleWindowSizeChange = (): void => globalStoreReducer([state, setState], StoreActions.UPDATE_IS_MOBILE);
    window.addEventListener('resize', handleWindowSizeChange);
    return (): any => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  },[]);

  return (
    <AppContext.Provider value={[state, setState]}>
      <ThemeProvider theme={theme}>
        {loading && <Loader size={75} sx={{height: '100vh', width: '100vw'}}/>}
        {!loading && maintenance && <Maintenance isMobile={state.isMobile}/>}
        {!loading && state.operation !== null ?
          <>
            <ScrollToTop />
            <Box sx={{display: "flex", flexDirection: "column", backgroundColor: theme.palette.ornament.main, minHeight: "100vh"}}>
              <GoogleAnalyticsTracker/>
              <Helmet>
                <link rel="icon" href={faviconUrl} />
                {/* ZOOM Fo vitrine */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
              </Helmet>
              <Box sx={{minHeight: !state.isMobile ? "calc(100vh - 182px)" : "calc(100vh - 292px)"}}>
                <Routes>
                  <Route path={PUBLIC_URL.HOME} element={privateRoute(customRoute(state.user, state.operation, <Home/>), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.BASKET} element={privateRoute(customRoute(state.user, state.operation, <Basket/>, false, true), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.BASKET_SHIPPING_ADDRESS} element={privateRoute(customRoute(state.user, state.operation, <BasketShippingAddress/>, false, true), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.BASKET_BILLING_ADDRESS} element={privateRoute(customRoute(state.user, state.operation, <BasketBillingAddress/>, false, true), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.BASKET_PAYMENT} element={privateRoute(customRoute(state.user, state.operation, <BasketPayment/>, false, true), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.ORDER_DONE} element={privateRoute(customRoute(state.user, state.operation, <OrderDone/>, false, true), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.GET_GIFT} element={privateRoute(customRoute(state.user, state.operation, <Gift/>, false, true, !!state.isPreview), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.LOGIN} element={publicRoute(<LoginPage/>)}/>
                  <Route path={PUBLIC_URL.RULES} element={privateRoute(customRoute(state.user, state.operation, <Rules/>), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.ACCOUNT} element={privateRoute(customRoute(state.user, state.operation, <Account/>, true, true), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.INFORMATION} element={privateRoute(customRoute(state.user, state.operation, <Information/>, true, true), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.ORDERS} element={privateRoute(customRoute(state.user, state.operation, <Orders/>, true, true), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.ORDER} element={privateRoute(customRoute(state.user, state.operation, <Order/>, true, true), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.RESET_PASSWORD} element={privateRoute(<ResetPassword/>, theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.POINTS} element={privateRoute(customRoute(state.user, state.operation, <Points/>, true), theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.FAQ} element={privateRoute(<Faq/>, theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.CONTACT} element={privateRoute(<Contact/>, theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.UNSUSCRIBE} element={privateRoute(<Unsuscribe/>, theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.MAINTENANCE_MODE_ON} element={privateRoute(<Maintenance isMobile={state.isMobile}/>, theme, state.isMobile)}/>
                  <Route path={PUBLIC_URL.EXPIRED_TOKEN} element={privateRoute(<ExpiredToken isMobile={state.isMobile}/>, theme, state.isMobile)}/>
                </Routes>
              </Box>
            </Box>
          </>
          :
          error && <Box sx={{m: 3, p: 3, backgroundColor: "error.light"}} dangerouslySetInnerHTML={{__html: error}}/>
        }
      </ThemeProvider>
    </AppContext.Provider>
  )
}
