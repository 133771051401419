import React, {useContext, useEffect, useState} from 'react';
import {Alert, Box, Checkbox, FormControl, FormControlLabel, LinearProgress, OutlinedInput, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SxProps, Theme} from '@mui/material/styles';
import {AppContext} from '../../App';
import {INPUT_TYPES} from '../../patterns/form/formConstants';
import {PrimaryBigButton} from '../../components/buttons/mainButton';
import {PUBLIC_URL} from '../../common/struct/urlManager';
import ElementInput from '../../patterns/form/elementInput';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {getMinimalUserInfo, postParticipantWithCode} from '../../services/ParticipantService';
import {login} from '../../services/AuthenticationService';
import {getOperationParticipantId} from '../../common/struct/globalVar';
import {TOperationParticipant} from '../../common/struct/models/TOperationParticipant';
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {useLocation, useNavigate} from 'react-router-dom';
import {StoreActions} from '../../common/struct/store';
import {manageStringError} from '../../common/methods/ApiService';

type propsRegisterWithCodeForm = {
  code: {value: string; mask: string;}
  amount: number|null
  sx?: SxProps<Theme>
}

export default function RegisterWithCodeForm({code, amount = 0, sx}: propsRegisterWithCodeForm): JSX.Element {
  const {t} = useTranslation();
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;
  const operation = store.operation;
  const navigate = useNavigate();
  const search = useLocation().search;
  const redirectUrl = new URLSearchParams(search).get('redirect');

  const [firstname, setFirstname] = useState<string | null>(null);
  const [lastname, setLastname] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [cgvu, setCgvu] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  
  const [passHasSize, setPassHasSize] = useState<boolean>(false);
  const [passHasUpper, setPassHasUpper] = useState<boolean>(false);
  const [passHasLower, setPassHasLower] = useState<boolean>(false);
  const [passHasDigit, setPassHasDigit] = useState<boolean>(false);
  const [passHasSpecial, setPassHasSpecial] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [progressState, setProgressState] = useState<string>(t('reset_password.progress_state.empty'));
  const [passwordViolations, setPasswordViolations] = useState<string[]>([]);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const submit = (e: any): void => {  
    setLoading(true);
    e.preventDefault();
    if (e.target.id === "register-form") {
      if (password && firstname && lastname && email && cgvu) {
        const participant = {
          firstname: firstname,
          lastname: lastname,
          email: email,
          phone: phone,
          password: password,
          participatedOperations: [
            {
              operation: "fo/operations/"+operation
            }
          ]
        }

        postParticipantWithCode(code.value.replace(/ /g, '').toUpperCase(), participant, operation).then((res) => {
          login(STORE, res.email.toLowerCase(), password, operation.id)
            .then(() => handleLoginSuccess())
            .catch((error) => handleLoginError(error))
        })
          .catch((error) => {
            manageStringError(typeof error === 'string' ? error : error[0].message, setError, t)
            setLoading(false);
          })          
      }
    }
  }

  const handleLoginSuccess = (): void => {
    getMinimalUserInfo(getOperationParticipantId() ?? "")
      .then((participant: TOperationParticipant | undefined) => {
        globalStoreReducer(STORE, StoreActions.LOGIN, {user: participant, stayConnected: true});
        setLoading(false);        
        navigate("/" + (redirectUrl && redirectUrl !== '' ? redirectUrl : "") + "?code=" + amount);
      })
  }

  const handleLoginError = (error: any): void => {
    setLoading(false);
    globalStoreReducer(STORE, StoreActions.LOGOUT);
    manageStringError(error, setError, t);
  }

  const checkPassword = (password: string): void => {
    
    const violations = [];

    setPassHasSize(password.length >= 8);
    if (password.length < 8) {
      violations.push(t('reset_password.password_violation.too_short'));
    }

    setPassHasUpper(/[A-Z]/.test(password));
    if (!/[A-Z]/.test(password)) {
      violations.push(t('reset_password.password_violation.no_uppercase'))
    }

    setPassHasLower(/[a-z]/.test(password));
    if (!/[a-z]/.test(password)) {
      violations.push(t('reset_password.password_violation.no_lowercase'));
    }

    setPassHasDigit(/\d/.test(password));
    if (!/\d/.test(password)) {
      violations.push(t('reset_password.password_violation.no_digit'));
    }
    

    setPassHasSpecial(/[-_@$!%*#?&+/\\]/.test(password));
    if (!/[-_@$!%*#?&+/\\]/.test(password)) {
      violations.push(t('reset_password.password_violation.no_special'))
    }

    if (violations.length === 0 || (violations.length === 1 && password.length >= 16)) {
      setProgressState(t('reset_password.progress_state.strong'));
    } else if (violations.length === 1) {
      setProgressState(t('reset_password.progress_state.medium'));
    } else if (password.length > 0) {
      setProgressState(t('reset_password.progress_state.weak'));
    } else {
      setProgressState(t('reset_password.progress_state.empty'));
    }

    setPassword(password);
    setPasswordViolations(violations);
  }

  useEffect(() => {
    const passwordCriteria = [passHasUpper, passHasLower, passHasDigit, passHasSpecial];

    setProgressValue([passHasSize, ...passwordCriteria].filter(value => value).length * 100 / [passHasSize, ...passwordCriteria].length);
    setCanSubmit(passHasSize && passwordCriteria.filter(value => !value).length < 1);
  }, [passHasSize, passHasUpper, passHasLower, passHasDigit, passHasSpecial])   

  return (
    <>
      <Box component="form" onSubmit={submit} id={"register-form"} sx={{display: 'flex', flexDirection: 'column', width: '100%', maxWidth: "600px", mx: "auto", ...sx}}>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 0}}>{t("register.title")}</Typography>
        <Typography variant="body1" color="neutral.dark" sx={{mb: 4}}>{t("register.subtitle")}</Typography>
        {error && <Alert severity="error" variant="filled" sx={{mb: 4, width: "initial !important"}}>{error}</Alert>}
        <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mb: 1}}>{t("register.firstname")}</Typography>
        <FormControl variant="outlined" sx={{mb: 4}}>
          <OutlinedInput
            id="firstname"
            name="firstname"
            type={INPUT_TYPES.DEFAULT}
            required={true}
            value={firstname}
            onChange={(e: any): any => setFirstname(e.target.value)}
            disabled={loading}
          />
        </FormControl>
        <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mb: 1}}>{t("register.lastname")}</Typography>
        <FormControl variant="outlined" sx={{mb: 4}}>
          <OutlinedInput
            id="lastname"
            name="lastname"
            type={INPUT_TYPES.DEFAULT}
            required={true}
            value={lastname}
            onChange={(e: any): any => setLastname(e.target.value)}
            disabled={loading}
          />
        </FormControl>
        <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mb: 1}}>{t("register.email")}</Typography>
        <FormControl variant="outlined" sx={{mb: 4}}>
          <OutlinedInput
            id="email"
            name="email"
            type={INPUT_TYPES.DEFAULT}
            required={true}
            value={email}
            onChange={(e: any): any => setEmail(e.target.value)}
            disabled={loading}
          />
        </FormControl>
        <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mb: 1}}>{t("register.phone")}</Typography>
        <FormControl variant="outlined" sx={{mb: 4}}>
          <OutlinedInput
            id="phone"
            name="phone"
            type={INPUT_TYPES.DEFAULT}
            required={false}
            value={phone}
            onChange={(e: any): any => setPhone(e.target.value)}
            disabled={loading}
          />
        </FormControl>
        <Box>
          <Box sx={{mb: 4}}>
            <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mb: 1}}>{t("register.password")}</Typography>
            <ElementInput name="password"
              label="reset_password.placeholder_security"
              type={INPUT_TYPES.PASSWORD}
              password={true}
              disabled={loading}
              value={password}
              updateValue={(e: any): void => checkPassword(e.target.value)}/>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <LinearProgress sx={{backgroundColor: "ornament.dark", flexGrow: 1}} color={canSubmit ? "success" : "error"} variant="determinate" value={progressValue} />
              <Typography variant="caption" display="inline" sx={{ml: 1, color: "neutral.main"}}>{progressState}</Typography>
            </Box>
            {passwordViolations.map((violation, index) => (
              <Box key={index} sx={{display: "flex", alignItems: "center"}}>
                <CheckCircleOutlineIcon sx={{color: "neutral.main", mr: 0}}/>
                <Typography variant="caption" display="inline" sx={{color: "neutral.main"}}>{violation}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <FormControlLabel sx={{mb: 4, display: "flex", alignItems: "center"}} control={<Checkbox checked={cgvu} onChange={(): void => setCgvu(!cgvu)} size="small" />} label={<Typography variant="body1" color="neutral.main" dangerouslySetInnerHTML={{__html: t("register.cgvu", {link_1: PUBLIC_URL.CGVU, link_2: PUBLIC_URL.PERSONAL_DATA})}} />} />
        <PrimaryBigButton submit={true} disabled={!canSubmit || !cgvu || !firstname || !lastname || !email} sx={{".MuiCircularProgress-root": {color: "primary.contrastText"}}} loading={loading} label={t("register.submit_button")}/>
        <Box sx={{maxWidth: "600px", mx: "auto", mt: 2}}>
          <Typography variant="caption" color="neutral.dark" sx={{"a": {textDecoration: "none", color: "neutral.main"}, "strong": {color: "neutral.dark"}}} dangerouslySetInnerHTML={{__html: t("login.datas_infos", {link: PUBLIC_URL.PERSONAL_DATA})}}/>
        </Box>
      </Box>
    </>
  )
}
