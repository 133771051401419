import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import Shop from "../shop/shop";
import {AppContext} from "../../App";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {Link, useSearchParams} from 'react-router-dom';
import CodeModal from '../points/codeModal';
import {initOperation} from '../../services/OperationService';
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {initialStore, StoreActions} from '../../common/struct/store';
import {TOperation} from '../../common/struct/models/TOperation';
import Loader from '../../components/loader/loader';
import {initOperationAdmin} from '../../services/AdminService';

export default function Home(): JSX.Element {
  const [store] = useContext<any>(AppContext);
  const [searchFilters] = useSearchParams();
  const isMobile = store.isMobile;
  const {t} = useTranslation();

  const [state, setState] = useState(initialStore);
  const [operation, setOperation] = useState<TOperation|null>(null);
  
  const hasBanner = operation && operation.bannerUrl ? true : false;
  const hasEdito = operation && operation.twig ? true : false;

  useEffect(() => {
    initOperation()
      .then((data: any): void => {
        globalStoreReducer([state, setState], StoreActions.SET_OPERATION, {operation: data});
        setOperation(data);
      })
      .catch(() => {
        initOperationAdmin()
          .then((data: any) => {
            globalStoreReducer([state, setState], StoreActions.SET_OPERATION, {operation: data});
            setOperation(data);
          })
      })
  }, []);

  const maxWidth = (isMobile) ? "100vw" : MAX_DESKTOP_SIZE;

  const paramFilters = Object.fromEntries(searchFilters); 
  
  const [amount, setAmount] = useState<number|null>(paramFilters["code"] ? parseInt(paramFilters["code"]) : null);
  const [codeModal, setCodeModal] = useState<boolean>((amount != null) ? true : false);

  const goToShop = (): void => {
    const element = document.getElementById("shop");
    element?.scrollIntoView();
  }
  const checkDate = operation && operation.startDate ? (new Date(operation.startDate) > new Date()) : false;

  useEffect(() => {    
    document.title = t("tab_title.home");
  }, []);

  useEffect(() => {    
    if (codeModal == false) {
      setTimeout(() => {
        setAmount(null);
      }, 300);
    } 
  }, [codeModal])

  const mobilePresentation = <>{operation != null && ((store.isShopOpen && hasEdito) || (!store.isShopOpen && store.isUserSav && hasEdito) || (!store.isShopOpen && !store.isUserSav)) && <Box sx={{padding: "22px 33px"}}>
    {(store.isShopOpen || store.isUserSav || store.isPreview) && <Box sx={{fontFamily: "Mulish", color: "neutral.dark", "& img": {width: "100%", height: "auto"}}}
      dangerouslySetInnerHTML={{__html: operation.twig ? operation.twig : ""}}/>}
    {(!store.isShopOpen && !store.isUserSav && !store.isPreview) && <Typography color="neutral.dark" variant="body1">
      {checkDate && operation && operation.startDate ? t("home.shop_closed.before", {date: new Date(operation.startDate)}) : operation && operation.endDate && t("home.shop_closed.after", {date: new Date(operation.endDate)})}
      <Link to={PUBLIC_URL.CONTACT}>{!store.isShopOpen && !store.isUserSav ? t("home.shop_closed.contact") : ''}</Link>
    </Typography>}
  </Box>}</>

  const desktopPresentation = <>{operation != null && ((store.isShopOpen && hasEdito) || (!store.isShopOpen && store.isUserSav && hasEdito) || (!store.isShopOpen && !store.isUserSav)) && <Box sx={{p: "22px 33px"}}>
    {(store.isShopOpen || store.isUserSav || store.isPreview) && <Box sx={{fontFamily: "Mulish", color: "neutral.dark", "& img": {width: "100%", height: "auto"}}}
      dangerouslySetInnerHTML={{__html: operation.twig ? operation.twig : ""}}/>}
    {(!store.isShopOpen && !store.isUserSav && !store.isPreview) && <Typography color="neutral.dark" variant="body1">
      {checkDate && operation && operation.startDate ? t("home.shop_closed.before", {date: new Date(operation.startDate)}) : operation && operation.endDate && t("home.shop_closed.after", {date: new Date(operation.endDate)})}
      <Link to={PUBLIC_URL.CONTACT}>{!store.isShopOpen && !store.isUserSav ? t("home.shop_closed.contact") : ''}</Link>
    </Typography>}
  </Box>}</>

  return !operation ? <Loader /> :
    <Box sx={{display: "flex", flexDirection: "column", zIndex: 0}}>
      <CodeModal codeModal={codeModal} amount={amount} setCodeModal={setCodeModal} home={true} operation={operation}/>
      <Box sx={{display: "flex", flexDirection: "column", width: "100%", maxWidth: maxWidth, mx: "auto", px: isMobile ? 4 : "0px", py: 4, boxSizing: "border-box"}} className="bloc-edito">
        <Box sx={{display: "flex", width: "100%"}}>
          {hasBanner && <Box sx={{flex: 1, borderRadius: "4px", borderColor: "ornament.dark", borderStyle: "solid", borderWidth: "1px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", borderBottomWidth: "0px"}}>
            <img src={operation.bannerUrl} style={{width: "100%", height: "100%", display: "block", objectFit: "contain", borderTopLeftRadius: "3px", borderTopRightRadius: "3px"}} />
          </Box>}
        </Box>
        <Box sx={{boxSizing: "border-box", borderColor: "ornament.dark", borderStyle: "solid", borderWidth: "1px", backgroundColor: "ornament.light", borderRadius: "4px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px", mt: "0px", borderBottomColor: "primary.main", borderBottomWidth: "8px"}}>
          <Box sx={{padding: "20px 32px", borderBottomStyle: "solid", borderBottomColor: "ornament.dark", borderBottomWidth: "1px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold"}}>{operation.name}</Typography>
            {(store.isShopOpen || store.isUserSav) && hasEdito && <Typography variant="body2" color="ornament.main" onClick={goToShop} sx={{cursor: "pointer", textAlign: "center", backgroundColor: "primary.main", padding: "4px 12px", borderRadius: "5px", fontWeight: 700, fontSize: "14px"}}>{t("home.view_shop")}</Typography>}
          </Box>
          {isMobile && mobilePresentation}
          {!isMobile && desktopPresentation}
        </Box>
      </Box>
      {(store.isShopOpen || store.isUserSav || store.isPreview) && <Shop operation={operation} isMobile={isMobile}/>}
    </Box>
}
