import React from 'react';
import {Box, Dialog, DialogContent, Divider, IconButton, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import {PrimaryBigButton} from '../../components/buttons/mainButton';
import CloseIcon from '@mui/icons-material/Close';
import {PUBLIC_URL} from '../../common/struct/urlManager';
import Oups from "../../assets/oups.png";
import Super from "../../assets/super.png";
import {TOperation} from '../../common/struct/models/TOperation';

export default function CodeModal(props: {codeModal: boolean, amount: number|null, setCodeModal: (arg: boolean) => void, home?: boolean, operation: TOperation}): JSX.Element {
  const {codeModal, amount, setCodeModal, home = false, operation} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  
  return (
    <Dialog open={codeModal} onClose={(): void => { setCodeModal(false) }} sx={{display: "flex", justifyContent: "center"}}>
      <Divider sx={{height: 4, backgroundColor: "primary.main", borderColor: "primary.main"}} flexItem/>
      <Box sx={{position: "relative", p: 3}}>
        <IconButton onClick={(): void => { setCodeModal(false) }} sx={{position: "absolute", right: 5, top: 5}}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
        <DialogContent sx={{pb: 5, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "start", py: 3}}>
              <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", mr: 6}}>
                {amount != 0 ? <img src={Super} alt="super points image" style={{maxHeight: "100px"}}/> : <img src={Oups} alt="oups no points image" style={{maxHeight: "100px"}}/>}
              </Box>
            </Box>
            <Box>
              <Typography variant="h2" color="neutral.dark" sx={{fontWeight: 700, pb: 2}}>{amount != 0 ? (operation.shop.pricing !== "UNIQUE" ? t("account.points.code_dialog.success.title") : t("account.points.code_dialog.success_unique.title")) : t("account.points.code_dialog.empty.title")}</Typography>
              <Typography variant="body1" color="neutral.main" sx={{fontWeight: 600, "span": {color: "primary.main"}}} dangerouslySetInnerHTML={{__html: amount != 0 ? (operation.shop.pricing !== "UNIQUE" ? t("account.points.code_dialog.success.subtitle", {"points": amount}) : t("account.points.code_dialog.success_unique.subtitle")) : t("account.points.code_dialog.empty.subtitle")}}/>
            </Box>
          </Box>
          <PrimaryBigButton label={amount != 0 ? (operation.shop.pricing !== "UNIQUE" ? t("account.points.code_dialog.success.button") : t("account.points.code_dialog.success_unique.button")) : t("account.points.code_dialog.empty.button")} action={(): void => { amount != 0 ? (!home ? navigate(PUBLIC_URL.HOME) : setCodeModal(false)) : (setCodeModal(false)) }} sx={{width: "fit-content", mt: 3}}/>
        </DialogContent>
      </Box>
    </Dialog>
  )
}
