import {getToken, stayConnected} from "../struct/globalVar";
import {API_BO_URL, API_FO_URL, PUBLIC_URL} from "../struct/urlManager";
import {TViolation} from "../struct/models/TViolation";
import {refreshToken} from "../../services/AuthenticationService";
import {globalStoreReducer} from "./context/globalStoreReducer";
import {StoreActions} from "../struct/store";
import {emptyFilter, ESorting, TFilter} from "../struct/models/TFilter";

const DEFAULT_HEADERS = {
  'Accept': 'application/ld+json,application/json',
  'Origin': '*',
  'Cache-Control': 'no-cache',
  'x-requested-with': 'XMLHttpRequest',
  'Accept-Language': 'fr'
};
const HEADERS_WITH_FORM_URL = {
  "Content-Type": "application/x-www-form-urlencoded",
};
const HEADERS_WITH_JSON = {
  ...DEFAULT_HEADERS,
  "Content-Type": "application/json"
};
export type RequestOrder = {
  field: string;
  sort: string;
}
export enum RequestFilterOperator {
  EQUALS = "=",
  LIKE = "like",
  IS = "IS",
  GREATER_THAN = ">",
  GREATER_THAN_OR_EQUALS = ">=",
  LESSER_THAN = "<",
  LESSER_THAN_OR_EQUALS = "<="
}
export type RequestFilterItem = {
  operator: RequestFilterOperator;
  value: string;
}
export type RequestFilter = {
  field: string;
  items: RequestFilterItem[];
}

const headersWithToken = (initialHeaders: HeadersInit = DEFAULT_HEADERS): Headers => {
  const token = getToken();
  if (token != null) {
    return new Headers({...initialHeaders, "Authorization": `Bearer ` + getToken()});
  } else {
    return new Headers({...initialHeaders});
  }
}

export function get(url: string, retried = false, basicEndpoint = false): Promise<any> {
  const request = new Request(`${!basicEndpoint ? API_FO_URL : API_BO_URL}/${url}`, {
    method: 'GET',
    headers: headersWithToken(),
    mode: 'cors'
  });
  return fetch(request)
    .then(response => manageResponse(response, retried ? undefined : (): Promise<void> => get(url, true)));
}

export function getWithoutToken(url: string, retried = false, basicEndpoint = false): Promise<any> {
  const request = new Request(`${!basicEndpoint ? API_FO_URL : API_BO_URL}/${url}`, {
    method: 'GET',
    headers: DEFAULT_HEADERS,
    mode: 'cors'
  });
  return fetch(request)
    .then(response => manageResponse(response, retried ? undefined : (): Promise<void> => get(url, true)));
}

export function getBlob(url: string, retried = false): Promise<any> {
  const request = new Request(`${API_FO_URL}/${url}`, {
    method: 'GET',
    headers: headersWithToken(),
    mode: 'cors'
  });
  return fetch(request).then((response: Response) => {
    const token = getToken();
    if (response.status === 401 && !retried && token !== null) {
      return refreshToken(token).then(() => getBlob(url, true))
    }
    return response;
  });
}

export function getPaginated(url: string, page: number, itemsPerPage: number, search?: string|null, order?: ESorting|null, filters: TFilter = emptyFilter, retried = false, basicEndpoint = false): Promise<{totalItems: number, items: any[]}> {
  const requestUrl = new URL(`${!basicEndpoint ? API_FO_URL : API_BO_URL}/${url}`);
  requestUrl.searchParams.append('page', page.toString());
  requestUrl.searchParams.append('itemsPerPage', itemsPerPage.toString());

  if (order) {
    switch (order) {
    case ESorting.POINTS_ASC:
      requestUrl.searchParams.append(`order[sellingPointPrice]`, "ASC");
      break;
    case ESorting.POINTS_DESC:
      requestUrl.searchParams.append(`order[sellingPointPrice]`, "DESC");
      break;
    case ESorting.NEW_GIFTS:
      requestUrl.searchParams.append(`order[create_date]`, "DESC");
      break;
    default:
      requestUrl.searchParams.append(`order[points]`, order);
    }
  }
  if (search) requestUrl.searchParams.append('simplesearch', search);
  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      if (value[0] !== undefined) {
        let requestValue = "";
        value.map((item) => {
          if (item !== value[0]) {
            requestValue = requestValue + "," + item.toString();
          } else {
            requestValue = requestValue + item.toString();
          }
        })

        requestUrl.searchParams.append(key, requestValue)
      }
    } else if (typeof value == "boolean") {
      if (value !== false) {
        requestUrl.searchParams.append(key, value.toString());
      }
    } else if (value !== null) {
      requestUrl.searchParams.append(key, value);
    }
  }
  if (basicEndpoint) requestUrl.searchParams.append('admin', "true");

  const request = new Request(requestUrl.href, {
    method: 'GET',
    headers: headersWithToken(),
    mode: 'cors'
  });
  return fetch(request)
    .then((response: Response) => {
      if (response.status === 401 && !retried && stayConnected()) {
        return getPaginated(url, page, itemsPerPage, search, order, filters, true);
      }
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json()
        .then((json) => {
          return {
            totalItems: json['hydra:totalItems'] ?? 0,
            items: json['hydra:member'] ?? []
          };
        })
        .catch(() => {
          return {
            totalItems: 0,
            items: []
          }
        });
    })
    .catch(() => {
      return {
        totalItems: 0,
        items: []
      }
    });
}

export function post(url: string, entity?: any, retried = false): Promise<any> {
  const request = new Request(`${API_FO_URL}/${url}`, {
    method: 'POST',
    headers: headersWithToken(HEADERS_WITH_JSON),
    mode: 'cors',
    body: (entity) ? JSON.stringify(entity) : null
  });
  return fetch(request)
    .then(response => manageResponse(response, retried ? undefined : (): Promise<void> => post(url, entity, true)));
}

export function postWithoutToken(url: string, entity: any): Promise<any> {
  const request = new Request(`${API_FO_URL}/${url}`, {
    method: 'POST',
    headers: new Headers(HEADERS_WITH_JSON),
    mode: 'cors',
    body: JSON.stringify(entity)
  });
  return fetch(request).then(manageResponse);
}

export function postExternalWithUrlEncoded(url: string, entity: any): Promise<any> {
  const params = new URLSearchParams();
  Object.keys(entity).forEach(key => params.append(key, entity[key]));

  const request = new Request(url, {
    method: 'POST',
    headers: new Headers(HEADERS_WITH_FORM_URL),
    mode: 'no-cors',
    body: params.toString()
  });
  return fetch(request).then(manageResponse);
}

export function postWithFile(url: string, entity: any, retried = false): Promise<any> {
  const request = new Request(`${API_FO_URL}/${url}`, {
    method: 'POST',
    headers: headersWithToken(),
    mode: 'cors',
    body: entity
  });
  return fetch(request)
    .then(response => manageResponse(response, retried ? undefined : (): Promise<void> => postWithFile(url, entity, true)));
}

export function put(url: string, id: string, entity: any, retried = false): Promise<any> {
  const request = new Request(`${API_FO_URL}/${url}/${id}`, {
    method: 'PUT',
    headers: headersWithToken(HEADERS_WITH_JSON),
    mode: 'cors',
    body: JSON.stringify(entity)
  });
  return fetch(request)
    .then(response => manageResponse(response, retried ? undefined : (): Promise<void> => put(url, id, entity, true)));
}

export function del(url: string, id: string, retried = false): Promise<any> {
  const request = new Request(`${API_FO_URL}/${url}/${id}`, {
    method: 'DELETE',
    headers: headersWithToken(),
    mode: 'cors'
  });
  return fetch(request)
    .then(response => manageResponse(response, retried ? undefined : (): Promise<void> => del(url, id, true)));
}

function manageResponse(response: Response, callback?: () => Promise<any>): Promise<any> {
  if (response.status === 401) {
    const token = getToken();
    if (callback && stayConnected() && token !== null) {
      return refreshToken(token).then(() => callback());
    }
    // TODO : Invitation token get 401 status code return
  }

  // Mock responses
  if (response.type === 'opaque' && response.status === 0) {
    return Promise.resolve(null);
  }
  if (!response.ok) {
    return response.json()
      .catch(() => Promise.reject(response))
      .then((json) => {
        if (json['violations']) {
          return Promise.reject(json['violations']);
        }
        if (json['hydra:description']) {
          return Promise.reject(json['hydra:description']);
        }
        if (json['detail']) {
          return Promise.reject(json['detail']);
        }
        return Promise.reject(response);
      });
  }
  return response.json()
    .then((json) => {
      return (json['hydra:member']) ? json['hydra:member'] : json;
    })
    .catch(() => null);
}

export function errorManager(error: any, t: any, navigate: any, STORE: any, redirectToLogin = true): string | TViolation[] {
  let errorMessage: any;
  if (Array.isArray(error)) {
    errorMessage = error.map((violation) => {
      return {
        field: violation.propertyPath,
        error: violation.message
      }
    });    
  } else if (error instanceof Response) {
    switch (error.status) {
    case 500:
      errorMessage = t('auth_provider.error500');
      break;
    case 503:
      navigate(PUBLIC_URL.MAINTENANCE_MODE_ON);
      break;
    case 401:
      if (redirectToLogin) {
        globalStoreReducer(STORE, StoreActions.LOGOUT);
        navigate(PUBLIC_URL.HOME);
      } else {
        errorMessage = t('auth_provider.error401');
      }
      break;
    default:
      errorMessage = t("global.default_error_message");
      break;
    }
  } else if (typeof error === "string") errorMessage = error;
  else errorMessage = t("global.default_error_message");

  return errorMessage;
}

export function manageStringError(error: string | TViolation[], setError: (error: string) => void, t: any): void {
  if (typeof error === 'string') setError(error);
  else if(typeof error === 'object' && Object(error).status === 403) {       
    setError(Object(error).headers.get('X-ErrorMessage') + (Object(error).headers.get('X-RateLimit-Remaining') <= 5 ? (" " + t('error.attempt_remaining', {attempt: Object(error).headers.get('X-RateLimit-Remaining')})) : ""))
  }
  else if(typeof error === 'object' && Object(error).status === 429) {
    setError(t('error.too_many_attempt', {count: Math.ceil(Object(error).headers.get('X-RateLimit-Retry-After') / 60)}))
  }
  else setError(t("global.default_error_message"));
}
