import {Alert, Box} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {addParticipantAddress} from "../../services/ParticipantService";
import {errorManager} from "../../common/methods/ApiService";
import {AppContext} from "../../App";
import {TViolation} from "../../common/struct/models/TViolation";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AddressForm from "../basket/addressForm";
import {TAddress, TAddressType} from "../../common/struct/models/TAddress";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {StoreContext} from "../../common/struct/store";
import {getOperationParticipantId} from "../../common/struct/globalVar";

interface InformationAddressProps {
  addresses: TAddress[];
  setAddresses: (addresses: TAddress[]) => void;
  type: TAddressType;
}

export default function InformationAddress(props: InformationAddressProps): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {addresses, setAddresses, type} = props;

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const [error, setError] = useState<string|null>(null);
  const [success, setSuccess] = useState<string|null>(null);
  const [violations, setViolations] = useState<TViolation[]>([]);
  const [address, setAddress] = useState<TAddress|undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setAddress(addresses.find(address => address.type == type));
    setLoading(false);
  }, [type])

  const updateAddress = (): void => {
    const operationParticipantId = getOperationParticipantId();
    if (address && store.user && operationParticipantId) {
      setLoading(true);
      setSuccess(null);
      setError(null);
      setViolations([]);

      addParticipantAddress(operationParticipantId, address, false)
        .then(user => {
          setAddresses(user.addresses);
          setSuccess(t("address.success"));
        })
        .catch(error => {
          const errorResult = errorManager(error, t, navigate, STORE);
          if (typeof errorResult === 'string') setError(errorResult);
          else if (setViolations && Array.isArray(errorResult)) setViolations(errorResult);
        })
        .finally(() => setLoading(false))
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    updateAddress();
  }

  return (
    <>
      {error && <Alert variant="filled" severity="error" sx={{mx: store.isMobile ? 3 : 7, mt: 5, mb: -2}}>{error}</Alert>}
      {success && <Alert variant="filled" severity="success" sx={{mx: store.isMobile ? 3 : 7, mt: 5, mb: -2}}>{success}</Alert>}
      <Box sx={{p: store.isMobile ? 3 : 7}}>
        <form onSubmit={handleSubmit}>
          <AddressForm address={address} setAddress={setAddress} violations={violations} type={type} loading={loading}/>
          <PrimaryBigButton loading={loading} submit={true} label={t("address.update_" + type + (store.isMobile ? "_mobile" : ""))} sx={{mt: 2, width: store.isMobile ? "100%" : "inherit"}}/>
        </form>
      </Box>
    </>
  )
}
