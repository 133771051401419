import React, {SetStateAction, useContext, useEffect, useRef, useState} from 'react';
import {TOperation} from "../../common/struct/models/TOperation";
import {Alert, Box, Card, Pagination} from "@mui/material";
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import {TGift} from "../../common/struct/models/TGift";
import Loader from "../../components/loader/loader";
import GiftCard from "./giftCard";
import {getShopBrands, getShopGifts, getShopUniverses} from "../../services/ShopService";
import {emptyFilter, ESorting, TFilter} from "../../common/struct/models/TFilter";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useNavigateParams} from "../../services/NavigateService";
import {PUBLIC_URL, shopUrl} from "../../common/struct/urlManager";
import DesktopUniverses from "./desktopUniverses";
import {TUniverse} from "../../common/struct/models/TUniverse";
import DesktopFilters from "./desktopFilters";
import Sorting from "./sorting";
import Search from "./search";
import MobileMenu from "./mobileMenu";
import MonetaryComplementCard from "./monetaryComplementCard";
import {AppContext} from "../../App";
import EmptySearchImage from "../../assets/empty_search.svg";
import useMediaQuery from '@mui/material/useMediaQuery';

interface ShopParameters {
  page: number;
  simplesearch: string|null;
  filters: TFilter;
  sorting: ESorting|null;
}

export default function Shop(props: {operation: TOperation, isMobile: boolean}): JSX.Element {
  const {operation, isMobile} = props;
  const maxWidth = (isMobile) ? "100vw" : MAX_DESKTOP_SIZE;
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const navigateSearch = useNavigateParams();
  const [searchFilters] = useSearchParams();
  const {state} = useLocation() as any ;
  const elementRef = useRef(null) as any;

  const [gifts, setGifts] = useState<TGift[]>([]);
  const [universes, setUniverses] = useState<TUniverse[]>([]);
  const [brands, setBrands] = useState<string[]>([]);
  const [loadingGifts, setLoadingGifts] = useState(false);
  const [reload, setReload] = useState(true);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);

  const sizeL = useMediaQuery('(min-width:1000px)');
  const sizeM = useMediaQuery('(min-width:580px)');
  const sizeS = useMediaQuery('(min-width:440px)');

  const initialShopParameters = (): ShopParameters => {
    const shopParams: ShopParameters = {
      page: 1,
      filters: emptyFilter,
      sorting: ESorting.NEW_GIFTS,
      simplesearch: null
    };
    const paramFilters = Object.fromEntries(searchFilters);
    const sorting = paramFilters["sort"];    

    { /** On passe les infos dans le state quand on revient d'une fiche produit pour éviter un relaod de page */ }
    { /** On passe les infos dans l'url quand on reload le shop*/ }
    if (state && state.filters && state.page) {
      if (state.filters.brands[0]) shopParams.filters.brands = state.filters.brands;
      if (state.filters.french) { shopParams.filters.french = true } else { shopParams.filters.french = false }
      if (state.filters.ecofriendly) { shopParams.filters.ecofriendly = true } else { shopParams.filters.ecofriendly = false }
      if (state.filters.minPoints) shopParams.filters.minPoints = parseInt(state.filters.minPoints);
      if (state.filters.maxPoints) shopParams.filters.maxPoints = parseInt(state.filters.maxPoints);
      if (state.filters.universIds[0]) shopParams.filters.universIds = state.filters.universIds;

      if (state.simplesearch) { shopParams.simplesearch = state.simplesearch } else { shopParams.simplesearch = null }

      shopParams.page = state.page;
      shopParams.sorting = state.sorting;

      if (!loading) {
        setLoading(true);
      }
    }
    else if (paramFilters) {
      if (paramFilters.brands) shopParams.filters.brands = paramFilters.brands.split(',');
      if (paramFilters.french == "true") shopParams.filters.french = true;
      if (paramFilters.ecofriendly == "true") shopParams.filters.ecofriendly = true;
      if (paramFilters.minPoints) shopParams.filters.minPoints = parseInt(paramFilters.minPoints);
      if (paramFilters.maxPoints) shopParams.filters.maxPoints = parseInt(paramFilters.maxPoints);
      if (paramFilters.universIds) shopParams.filters.universIds = paramFilters.universIds.split(',').map(Number);
      
      shopParams.page = paramFilters.page ? parseInt(paramFilters.page) : 1;
      shopParams.sorting = sorting == "POINTS_ASC" ? ESorting.POINTS_ASC : sorting == "POINTS_DESC" ? ESorting.POINTS_DESC : sorting == "NEW_GIFTS" ? ESorting.NEW_GIFTS : null;
      shopParams.simplesearch = paramFilters.simplesearch;
    }

    return shopParams;
  }

  const initialParameters = initialShopParameters();
  const [error, setError] = useState<string | null>(null);
  const [pageTotal, setPageTotal] = useState<number>(Math.ceil(0));
  const [pageNum, setPageNum] = useState(initialParameters.page);
  const [filters, setFilters] = useState<TFilter>(initialParameters.filters);
  const [sorting, setSorting] = useState<ESorting>(initialParameters.sorting ?? ESorting.NEW_GIFTS);
  const [search, setSearch] = useState<string|null>(initialParameters.simplesearch);
  const [scrollValue, setScrollValue] = useState(0);
  const [loadingScroll, setLoadingScroll] = useState<boolean>(true);

  const updateFilters = (filter: SetStateAction<TFilter>): void => {
    setFilters(filter);
    setPageNum(1);
  }
  const updateSearch = (search: SetStateAction<string|null>): void => {
    setSearch(search);
    setPageNum(1);
  }

  const loadGifts = (): Promise<void> => {
    if (!operation.shop) {
      setLoadingGifts(false);
      return Promise.resolve();
    }
    if (loadingGifts || !reload) {
      return Promise.resolve();
    }

    setLoadingGifts(true);
    if (firstLoading) {
      setFirstLoading(false);
    } else if (!isMobile) {
      goToShop();
    }    

    return getShopGifts(operation.shop.id, pageNum, operation.complementActivated || operation.complementActivated == undefined ? 59 : 60, filters, sorting, search, !store.isPreview ? false : true)
      .then((data: any) => {
        setPageTotal(Math.ceil(data.totalItems / (operation.complementActivated ? 59 : 60)));
        setGifts(data.items);
      })
      .catch((error) => manageStringError(errorManager(error, t, navigate, STORE), setError, t))
      .finally(() => {
        setReload(false);
        setLoadingGifts(false);
      });
  }

  const loadUniverses = (): Promise<void> => {
    if (!operation.shop) {
      setLoadingGifts(false);
      return Promise.resolve();
    }

    return getShopUniverses(operation.shop.id)
      .then((data: any) => setUniverses(data))
      .catch((error) => manageStringError(errorManager(error, t, navigate, STORE), setError, t))
  }

  const loadBrands = (): Promise<void> => {
    if (!operation.shop) {
      setLoadingGifts(false);
      return Promise.resolve();
    }

    return getShopBrands(operation.shop.id)
      .then((data: any) => setBrands(data))
      .catch((error) => manageStringError(errorManager(error, t, navigate, STORE), setError, t))
  }

  const goToShop = (): void => {
    const element = document.getElementById("shop");
    element?.scrollIntoView();
  }

  useEffect(() => {
    loadGifts();
    if (params.giftId === undefined) {
      navigateSearch(PUBLIC_URL.HOME,
        {
          ...(pageNum && pageNum > 1 && {page: pageNum}),
          ...(search && {simplesearch: search}),
          ...(filters.universIds.length > 0 && {universIds: filters.universIds}),
          ...(filters.brands.length > 0 && {brands: filters.brands.join(',')}),
          ...(filters.french && {french: filters.french}),
          ...(filters.ecofriendly && {ecofriendly: filters.ecofriendly}),
          ...(filters.minPoints && {minPoints: filters.minPoints}),
          ...(filters.maxPoints && {maxPoints: filters.maxPoints}),
          ...(sorting && sorting == ESorting.POINTS_ASC && {sort: ESorting.POINTS_ASC}),
          ...(sorting && sorting == ESorting.POINTS_DESC && {sort: ESorting.POINTS_DESC}),
          ...(sorting && sorting == ESorting.NEW_GIFTS && {sort: ESorting.NEW_GIFTS}),
        },
        state && state.scroll ? state.scroll : 0
      )
    }
  }, [pageNum, filters, sorting, reload]);

  useEffect(() => {
    if (state?.page) setPageNum(state.page)
    if (state?.filters) setFilters(state.filters)
    if (state?.sorting) setSorting(state.sorting)
    if (state?.search) setSearch(state.search)
  }, [state]);

  useEffect(() => {
    if (state && state.scroll && !loadingScroll) {
      window.scrollTo(0, state.scroll);
      setLoading(false);
      setLoadingScroll(true);
    }
  }, [loadingScroll, state]);

  useEffect(() => {
    loadBrands()
    loadUniverses()
  }, []);

  useEffect(() => {
    const onScroll = (e: any):number => {
      setScrollValue(e.target.documentElement.scrollTop);
      return scrollValue
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollValue]);

  const paginationChange = (event: React.ChangeEvent<unknown>, value: number): any => {
    if (value !== pageNum) {
      setGifts([]);
      setPageNum(value);
      setReload(true);
      loadGifts();
      goToShop();
    }
  }

  const desktopFilters = <Box sx={{display: "flex", flexDirection: "column", width: "20%", mr: 4}}>
    {/** Univers */}
    <DesktopUniverses universes={universes} filters={filters} setFilters={updateFilters} setReload={setReload} reload={reload}/>
    {/** Filtrer */}
    <DesktopFilters filters={filters} setFilters={updateFilters} brands={brands} operation={operation} setReload={setReload} reload={reload}/>
    {/** Tri */}
    {operation.shop && operation.shop.pricing !== "UNIQUE" && <Card sx={{display: "flex", flexDirection: "column", boxShadow: 0, pt: 1, mt: 4, borderWidth: 1, borderStyle: "solid", borderColor: "ornament.dark"}}>
      <Sorting sorting={sorting} setSorting={setSorting} setReload={setReload} reload={reload}/>
    </Card>}
  </Box>

  const desktopSearch = <Box sx={{display: "flex", mb: 4, width: "100%"}}>
    <Search search={search} setSearch={updateSearch} setReload={setReload} reload={reload} label={t("shop.filters.search.label_desktop")}/>
  </Box>

  const columnGap = (isMobile) ? 2 : 4;
  const giftsPadding = (isMobile) ? 2 : "0px";
  const giftsContainer = <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
    <Box sx={{display: "grid", width: "100%", boxSizing: "border-box", gridTemplateColumns: `repeat(${sizeL ? 4 : sizeM ? 3 : sizeS ? 2 : 1}, 1fr)`, p: giftsPadding, columnGap: columnGap, rowGap: columnGap}}>
      {gifts.map((gift: TGift, index: number) => 
        pageNum%2 != 0 && operation.complementActivated && index == 5 ? <><MonetaryComplementCard isMobile={isMobile} /><Link to={shopUrl(PUBLIC_URL.GET_GIFT, gift.id.toString())} state={{filters: filters, sorting: sorting, page: pageNum, simplesearch: search, scroll: scrollValue}} style={{textDecoration: "none"}} key={gift.id}><GiftCard key={gift.id} gift={gift} isMobile={isMobile}/></Link></>
          : pageNum%2 == 0 && operation.complementActivated && index == 54 ? <><MonetaryComplementCard isMobile={isMobile} /><Link to={shopUrl(PUBLIC_URL.GET_GIFT, gift.id.toString())} state={{filters: filters, sorting: sorting, page: pageNum, simplesearch: search, scroll: scrollValue}} style={{textDecoration: "none"}} key={gift.id}><GiftCard key={gift.id} gift={gift} isMobile={isMobile}/></Link></>
            : <Link to={shopUrl(PUBLIC_URL.GET_GIFT, gift.id.toString())} state={{filters: filters, sorting: sorting, page: pageNum, simplesearch: search, scroll: scrollValue}} style={{textDecoration: "none"}} key={gift.id}><GiftCard key={gift.id} gift={gift} isMobile={isMobile}/></Link>)
      }
    </Box>
    <Pagination
      count={pageTotal}
      onChange={paginationChange}
      page={pageNum}
      color="primary" variant="outlined"
      sx={{mt: 6, "& .Mui-selected": {backgroundColor: "primary.main", color: "primary.contrastText", borderColor: "transparent",
        "&:hover": {backgroundColor: "primary.dark"}}}}
    />
  </Box>

  const containerWidth = (isMobile) ? "100%" : "80%";
  return operation.shop ?
    <Box id="shop" sx={{display: "flex", width: "100%", maxWidth: maxWidth, mx: "auto", mr: "auto", mb: 4}} ref={elementRef} onLoad={(): void => { loading && setLoadingScroll(false) }} >
      {!isMobile && desktopFilters}
      <Box sx={{display: "flex", flexDirection: "column", width: containerWidth}}>
        {!isMobile && desktopSearch}
        {isMobile &&
          <MobileMenu filters={filters} setFilters={updateFilters} search={search} setSearch={updateSearch} sorting={sorting} setSorting={setSorting} universes={universes} brands={brands} setReload={setReload}/>
        }
        {loadingGifts && <Loader size={75} sx={{position: "sticky", top: "0%", height: "100vh", mt: "-350px"}}/>}
        {!loadingGifts && !error && gifts.length > 0 && giftsContainer}
        {!loadingGifts && !error && gifts.length == 0 && <img src={EmptySearchImage} style={{height: isMobile ? "250px" : "378px"}} alt="aucun resultat trouvé"/>}
        {error && <Alert variant="filled" severity="error">{error}</Alert>}
      </Box>
    </Box> : <Loader size={75} sx={{position: "sticky", top: "0%", height: "100vh"}}/>
}