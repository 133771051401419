import {API_URL, GLOBAL_OPERATION_PARTICIPANT_ID} from "../common/struct/urlManager";
import {get, post, put} from "../common/methods/ApiService";
import {TUserUpdate} from "../common/struct/models/TUserUpdate";
import {TAddress} from "../common/struct/models/TAddress";
import {TUser} from "../common/struct/models/TUser";
import {TOrder} from "../common/struct/models/TOrder";
import {TOperationParticipant} from "../common/struct/models/TOperationParticipant";
import {TBasket} from "../common/struct/models/TBasket";
import {TOperation} from "../common/struct/models/TOperation";

export async function getParticipant(userId: string): Promise<any> {
  return get((API_URL.GET_PARTICIPANT).replace(":" + GLOBAL_OPERATION_PARTICIPANT_ID, userId));
}

export async function updateParticipantPassword(operationParticipantId: string, oldPassword: string, password: string): Promise<any> {
  return put(API_URL.UPDATE_OPERATION_PARTICIPANT, operationParticipantId + "/update-password", {newPassword: password, oldPassword: oldPassword});
}

export async function reinitializeParticipantPassword(operationParticipantId: string, password: string, cgvuDate: Date): Promise<TOperationParticipant> {
  return put(API_URL.UPDATE_OPERATION_PARTICIPANT, operationParticipantId, {newPassword: password, cgvuDate: cgvuDate});
}

export async function addParticipantAddress(userId: string, address: TAddress, cgvuDate: boolean): Promise<TUser> {
  if (!cgvuDate) {
    return put(API_URL.UPDATE_OPERATION_PARTICIPANT, userId + "/address", {addresses: [address]});
  } else {
    return put(API_URL.UPDATE_OPERATION_PARTICIPANT, userId + "/address", {addresses: [address], cgvuDate: new Date()});
  }
}

export async function getUserAddresses(userId: string): Promise<{lastAddresses: TAddress[], addresses: TAddress[]}> {
  return get((API_URL.GET_PARTICIPANT_ADDRESSES).replace(":" + GLOBAL_OPERATION_PARTICIPANT_ID, userId));
}

export async function getMinimalUserInfo(userId: string): Promise<TOperationParticipant> {
  return get((API_URL.GET_MINIMAL_USER_INFO).replace(":" + GLOBAL_OPERATION_PARTICIPANT_ID, userId));
}

export async function updateUserInfo(operationParticipantId: string, user: TUserUpdate): Promise<any> {
  return put(API_URL.UPDATE_OPERATION_PARTICIPANT, operationParticipantId, {participant: {id: API_URL.UPDATE_PARTICIPANTS + user.id, firstName: user.firstName, lastName: user.lastName, phone: user.phone, email: user.email}});
}

export async function updateUserUnsuscribeDate(operationParticipantId: string, userId: string): Promise<any> {
  return put(API_URL.UPDATE_OPERATION_PARTICIPANT, operationParticipantId, {participant: {id: API_URL.UPDATE_PARTICIPANTS + userId, unsuscribeDate: new Date()}});
}

export async function updateParticipantBasket(operationParticipantId: string, basket: TBasket): Promise<TBasket> {
  return put(API_URL.UPDATE_OPERATION_PARTICIPANT, operationParticipantId + "/basket", {basket: basket}).then(participant => participant.basket);
}

export async function getUserOrder(orderId: string): Promise<TOrder> {
  return get(`${API_URL.GIFT_ORDERS}/${orderId}`);
}

export async function getUserReward(operationParticipantId: string, category: boolean | null): Promise<any> {
  return get((API_URL.GET_USER_REWARDS + "?order[createdAt]=DESC" + (category ? "&category=UNITARY" : category !== null ? "&category=ORDER" : "")).replace(":" + GLOBAL_OPERATION_PARTICIPANT_ID, operationParticipantId));
}

export async function postParticipantWithCode(accessCode: string, participant: any, operation: TOperation): Promise<TUser> {
  return post(`${API_URL.POST_PARTICIPANTS}?code=${accessCode}`, {firstName: participant.firstname, lastName: participant.lastname, email: participant.email, phone: participant.phone, password: participant.password, participatedOperations: [{operation: "fo/operations/"+operation.id}]});
}