import {TReward, TRewardCategory} from "../../common/struct/models/TReward";
import {Box, Divider, Typography} from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import React, {ElementType, useContext, useState} from "react";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

type RewardCategoryProps = {
  textColor: string,
  iconColor: string,
  backgroundColor: string,
  label: string,
  text: string,
  icon: ElementType
}

export default function RewardCard(props: {reward: TReward, isLastOne: boolean}): JSX.Element {
  const {t} = useTranslation();
  const {reward, isLastOne} = props;
  const [store] = useContext<any>(AppContext);
  const [showDetails, setShowDetails] = useState(false);

  const handleShowDetails = (): void => {
    setShowDetails(prevState => !prevState);
  }

  const extractCategoryProps = (reward: TReward): RewardCategoryProps => {
    switch (reward.category) {
    case TRewardCategory.UNITARY_CREDIT:
    case TRewardCategory.IMPORT_CREDIT:
      return {
        textColor: "success.main",
        iconColor: "success.main",
        backgroundColor: "#E0F0ED",
        label: !reward.accessCode ? t("account.points.operations.attribution") : t("account.points.operations.code"),
        text: !reward.accessCode ? t("account.points.operations.attribution_message") : t("account.points.operations.code_message", {code: reward.accessCode.code}),
        icon: !reward.accessCode ? StarsIcon : LocalActivityIcon
      }
    case TRewardCategory.UNITARY_DEBIT:
    case TRewardCategory.IMPORT_DEBIT:
      return {
        textColor: "warning.main",
        iconColor: "warning.main",
        backgroundColor: "#F0E8E0",
        label: t("account.points.operations.reprise"),
        text: t("account.points.operations.reprise_message"),
        icon: PriorityHighIcon
      }
    case TRewardCategory.REFUND:
      return {
        textColor: "success.main",
        iconColor: "success.main",
        backgroundColor: "#E0F0ED",
        label: t("account.points.operations.refund"),
        text: t("account.points.operations.refund_message"),
        icon: AutorenewIcon
      }
    case TRewardCategory.ORDER:
      return {
        textColor: "neutral.dark",
        iconColor: "neutral.main",
        backgroundColor: "ornament.dark",
        label: t("account.points.operations.withdraw"),
        text: t("account.points.operations.order", {date: new Date(reward.createdAt)}),
        icon: AccountBalanceWalletIcon
      }
    case TRewardCategory.CODE:
      return {
        textColor: "neutral.dark",
        iconColor: "neutral.main",
        backgroundColor: "ornament.dark",
        label: t("account.points.operations.code"),
        text: t("account.points.operations.code_message", {code: reward.accessCode?.code}),
        icon: LocalActivityIcon
      }
    case TRewardCategory.EXPIRATION:
      return {
        textColor: "neutral.dark",
        iconColor: "neutral.light",
        backgroundColor: "neutral.main",
        label: t("account.points.operations.expiration" + (store.isMobile ? "_mobile" : "")),
        text: t("account.points.operations.expiration_message"),
        icon: StopCircleIcon
      }
    }
  }

  const categoryProps = extractCategoryProps(reward);
  const Icon = categoryProps.icon;
  if (store.isMobile) {
    return (
      <Box sx={{display: "flex", flexDirection: "column", backgroundColor: reward.category === TRewardCategory.EXPIRATION ? "ornament.dark" : "transparent", borderBottom: isLastOne ? "none" : "1px solid", borderColor: "ornament.dark", cursor: "pointer"}} onClick={handleShowDetails}>
        <Box sx={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", mb: 0, pt: 2, px: 2, boxSizing: "border-box"}}>
          <Typography variant="body2" color="neutral.dark">{t("account.points.date", {date: new Date(reward.createdAt)})}</Typography>
          <Box sx={{display: "flex"}}>
            <Typography variant="body2" color={categoryProps.textColor} sx={{fontWeight: "bold"}}>
              {reward.pointAmount > 0 ? "+" + reward.pointAmount : reward.pointAmount}
            </Typography>
            <Typography variant="body2" color={categoryProps.textColor} sx={{fontWeight: "bold", fontSize: "10px", ml: "5px", mt: "-4px"}}>
              {t('account.points.point', {count: reward.pointAmount})}
            </Typography>
          </Box>
        </Box>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", px: 2, pb: 2, boxSizing: "border-box"}}>
          <Box sx={{display: "flex", alignItems: "center", overflowX: "hidden"}}>
            <Icon sx={{height: "24px", width: "24px", color: categoryProps.iconColor, p: 1, backgroundColor: categoryProps.backgroundColor, borderRadius: "50%"}} />
            <Typography variant="body2" color={categoryProps.textColor} sx={{fontWeight: "bold", mx: 1}}>{categoryProps.label}</Typography>
            <Divider orientation="vertical" sx={{backgroundColor: "neutral.light", borderColor: "neutral.light", width: "1px", height: "12px"}}/>
            <Typography noWrap variant="body2" color="neutral.dark" sx={{mx: 1}}>{categoryProps.text}</Typography>
          </Box>
          {showDetails && <KeyboardArrowUpIcon sx={{height: "18px", width: "18px"}}/>}
          {!showDetails && <KeyboardArrowDownIcon sx={{height: "18px", width: "18px"}}/>}
        </Box>
        {showDetails &&
          <Box sx={{boxSizing: "border-box", width: "100%", backgroundColor: categoryProps.backgroundColor}}>
            <Box sx={{alignItems: "center", height: "100%", width: "100%", flexDirection: "column", px: 2, py: 0, boxSizing: "border-box"}}>
              {reward.category == TRewardCategory.ORDER && reward.giftOrder && reward.giftOrder.lines.map((line, key) =>
                <Box sx={{display: "flex", width: "100%"}} key={key}>
                  <Typography variant="body2" color={categoryProps.textColor} sx={{mr: 0}}>{line.name}</Typography>
                  <Typography variant="body2" color={categoryProps.textColor} sx={{fontWeight: "bold"}}>{"x" + line.quantity}</Typography>
                </Box>
              )}
              {reward.category !== TRewardCategory.ORDER && <Typography variant="body2" color={categoryProps.textColor}>{categoryProps.text}</Typography>}
            </Box>
          </Box>
        }
      </Box>
    )
  }

  return (
    <Box sx={{py: 3, px: 6, display: "flex", alignItems: "center", backgroundColor: reward.category === TRewardCategory.EXPIRATION ? "ornament.dark" : "transparent", borderBottom: isLastOne ? "none" : "1px solid", borderColor: "ornament.dark"}}>
      <Typography variant="body2" color="neutral.dark" sx={{fontWeight: 400, mr: 6}}>{t("account.points.date", {date: new Date(reward.createdAt)})}</Typography>
      <Box sx={{display: "flex", alignItems: "center", width: "100%", mr: 1}}>
        <Icon sx={{height: "24px", width: "24px", color: categoryProps.iconColor, p: 1, backgroundColor: categoryProps.backgroundColor, borderRadius: "50%"}} />
        <Typography variant="body2" color={categoryProps.textColor} sx={{fontWeight: "bold", mx: 1}}>{categoryProps.label}</Typography>
        <Divider orientation="vertical" sx={{backgroundColor: "neutral.light", borderColor: "neutral.light", width: "1px", height: "12px"}}/>
        <Typography variant="body2" color="neutral.dark" sx={{mx: 1}}>{categoryProps.text}</Typography>
        {reward.category === "ORDER" &&
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Divider orientation="vertical" sx={{backgroundColor: "neutral.light", borderColor: "neutral.light", width: "1px", height: "12px"}}/>
          <Typography variant="body2" color="neutral.dark" sx={{mx: 1}}>{t("account.points.operations.gift_count", {count: Object.values(reward.giftOrder.lines).reduce((r, {quantity}) => r + quantity, 0)})}</Typography>
        </Box>}
      </Box>
      <Box sx={{display: "flex", ml: 6}}>
        <Typography variant="body1" color={(reward.category === "ORDER" || reward.pointAmount == 0) ? "neutral.dark" : "success.main"} sx={{fontWeight: "bold"}}>
          {reward.pointAmount > 0 ? "+" + reward.pointAmount : reward.pointAmount}
        </Typography>
        <Typography variant="body1" color={(reward.category === "ORDER" || reward.pointAmount == 0) ? "neutral.dark" : "success.main"} sx={{fontWeight: "bold", fontSize: "14px", ml: "5px", mt: "-4px"}}>
          {t('account.points.point', {count: reward.pointAmount})}
        </Typography>
      </Box>
    </Box>
  )
}
